<div class="project-card">
  <div class="card-content col-12">
    <div class="image-container">
      <media-image
        width="100%"
        appereance="rounded"
        aspectRatio="16/3"
        objectFit="contain"
        [src]="project.coverImage.imagesData.variants[0]"
        alt="Project image"
      ></media-image>
    </div>
  </div>
  <div class="col-12 mt-3">
    <div class="row">
      <div class="col-md-6 col-12 d-flex flex-column gap-3">
        <h3 class="header">{{ project.name }}</h3>
        <span class="desc"
          >{{ convertProjectStatus(project.status) }} •
          {{ project.startDate | dateMonthYearNumber }} -
          {{
            project.endDate ? (project.endDate | dateMonthYearNumber) : ""
          }}</span
        >
      </div>
      <div
        class="col-md-6 col-12 d-flex justify-content-end align-items-center gap-3"
      >
        <!--<button mat-button><mat-icon>more_horiz</mat-icon></button>-->

        <button mat-flat-button color="accent" class="button-other" (click)="copyProfileLink()">
          Paylaş <mat-icon>share</mat-icon>
        </button>
        <button [disabled]="isButtonDisabled()" mat-flat-button color="accent" class="button-primary" (click)="buttonClick()">
          {{getButtonText()}}
        </button>
      </div>
    </div>
  </div>
  <div class="col-12 d-flex align-items-center gap-3 mt-4">
    <media-image
      appereance="circle"
      width="40px"
      height="40px"
      objectFit="contain"
      *ngFor="let contributor of project.contributors"
      [src]="contributor.profile.avatar"
    />
    <span style="margin-left: 10px">
      <b *ngFor="let contributor of project.contributors; let i = index"
        >{{ contributor.profile.display_name || contributor.profile.name }}
        <ng-container *ngIf="i < project.contributors.length - 1"
          >,
        </ng-container></b
      >
      tarafından düzenleniyor
    </span>
  </div>
  <div class="col-12 customStepper flex-wrap">
    <span
      (click)="activeStepCounter(0)"
      [class.activeStep]="_activeStep === 0"
      class="step"
    >
      Proje Detayı</span
    >
    <span
      (click)="activeStepCounter(1)"
      [class.activeStep]="_activeStep === 1"
      class="step"
    >
      Güncellemeler</span
    >
    <span
      (click)="activeStepCounter(2)"
      [class.activeStep]="_activeStep === 2"
      class="step"
    >
      Katılımcılar</span
    >
    <span
      (click)="activeStepCounter(3)"
      [class.activeStep]="_activeStep === 3"
      class="step"
    >
      Düzenleyiciler ve Sponsorlar</span
    >
    <span
      (click)="activeStepCounter(4)"
      [class.activeStep]="_activeStep === 4"
      class="step"
    >
      Görevler</span
    >
  </div>
</div>
