import { Component, Input } from '@angular/core';
import { Event } from 'src/app/models/event';

@Component({
  selector: 'events-card',
  templateUrl: './events-card.component.html',
  styleUrls: ['./events-card.component.scss']
})
export class EventsCardComponent {

  @Input() data: Event

  colors: string[] = ['#957AB6', '#876AAD', '#7A59A4', '#6E5094', '#624783'];
  items: number[] = [1, 2, 3, 4, 5];
  
  public getMappedData() {
    return {
      type: 'Event',
      id: this.data._id,
    };
  }
}
