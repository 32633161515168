import { Pipe, PipeTransform } from '@angular/core';
import { Profile } from 'src/app/models/profile';

@Pipe({
  name: 'appUrl',
})
export class AppUrlPipe implements PipeTransform {
  transform(value?: Profile, ...args: any[]): string[] {
    if (!value) return [];
    if (value?.type == 'Community') {
      return ['/community', value._id];
    } else if (value?.type == 'User') {
      return ['/user-profile', value.name];
    } else if (value?.type == 'Company') {
      return ['/company', value._id];
    } else if (value?.type == 'University') {
      return ['/school', value._id];
    }
    return ["/"];
  }
}
