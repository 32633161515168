<!--       COMPANIES        -->
<div class="container" *ngIf="items">
  <div class="content">
    <div class="highlights">
      <div class="text-container">
        <p>
          Şirketler
          <a
            [routerLink]="['/companies-extended']"
            routerLinkActive="router-link-active"
            class="button-filter"
            >Hepsini Gör</a
          >
        </p>
      </div>
    </div>
    <div
      class="row align-items-stretch"
      *ngIf="items?.length > 0; else noResult"
    >
      <div class="col-sm-4 mt-4" *ngFor="let item of items">
        <mat-card
          appearance="outlined"
          class="mat-elevation-z4 hover-effect-card"
          style="height: 100%"
          [routerLink]="['/company', item._id]"
        >
          <img
            mat-card-image
            [src]="item.backdrop"
            style="aspect-ratio: 16/3; object-fit: cover"
          />
          <mat-card-content>
            <mat-card-title style="margin-top: 5px">{{
              item.name
            }}</mat-card-title>

            <mat-card-subtitle>
              {{ item.followerCount || "0" }} Takipçi •
              {{ item.headnote }}</mat-card-subtitle
            >
          </mat-card-content>
          <mat-card-actions>
            <follow-button [profile]="item"></follow-button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
    <ng-template #noResult>
      <div class="no-result">
        <p>Aradığınız kriterlere uygun kişi bulunamadı.</p>
      </div>
    </ng-template>
  </div>
</div>
<br />
