import { Component, Input } from '@angular/core';
import { CustomPage } from 'src/app/models/custom-page';

@Component({
  selector: 'blog-card',
  templateUrl: './blog-card.component.html',
  styleUrls: ['./blog-card.component.scss']
})
export class BlogCardComponent {

  @Input() public blog: CustomPage;

  public replaceHtmlTags(text: string) {
    return text.replace(/<[^>]*>/g, '');
  }

  public limitedTags() {
    return (this.blog.tags || []).slice(0, 3);
  }

}
