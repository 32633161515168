import { Component, Input } from '@angular/core';
import { Community } from 'src/app/models/community';

@Component({
  selector: 'community-card',
  templateUrl: './community-card.component.html',
  styleUrls: ['./community-card.component.scss']
})
export class CommunityCardComponent {
  @Input() data: Community;

  public getMappedData() {
    return {
      type: 'Community',
      id: this.data._id,
    };
  }
}
