<h1 mat-dialog-title>Aramıza Katılın</h1>
<div mat-dialog-content style="width: 100%; padding-top: 16px;">
  <form [formGroup]="form">
    <mat-form-field appearance="outline" class="input">
      <mat-label>Adınız ve Soyadınız</mat-label>
      <input matInput formControlName="name" required="" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="input">
      <mat-label>Email Adresiniz</mat-label>
      <input matInput formControlName="email" required="" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="input">
      <mat-label>Unilocked'u nereden duydunuz?</mat-label>
      <input matInput formControlName="source" required="" />
    </mat-form-field>
    <mat-form-field appearance="outline" class="input">
      <mat-label>Bağlı olduğunuz okul, şirket, kurum vs. var mı?</mat-label>
      <input matInput formControlName="organization" required="" />
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="">İptal</button>
  <button mat-button cdkFocusInitial (click)="submit()" *ngIf="canSubmit()">
    Gönder
  </button>
</div>
