import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as tus from 'tus-js-client';
import { UploadDialogComponent } from './upload-dialog/upload-dialog.component';
import { BehaviorSubject, map } from 'rxjs';
import { AppHttpClient } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private dialog: MatDialog, private http: AppHttpClient) {}

  public uploadProgres: { [key: string]: BehaviorSubject<number> } = {};

  public upload(
    file: File | { name: string; type: string; blob: Blob },
    onFinish?: (id: string, fileEntry: any) => void
  ) {
    var upload = new tus.Upload(file instanceof File ? file : file.blob, {
      endpoint: '/files',
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        filename: file.name,
        filetype: file.type,
      },
      onBeforeRequest: function (req) {
        return new Promise<void>((resolve) => {
          var xhr = req.getUnderlyingObject();

          resolve();
        });
      },
      onError: function (error) {
        console.log('Failed because: ' + error);
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        console.log(bytesUploaded, bytesTotal, percentage + '%');
        if (!this.uploadProgres[file.name]) {
          this.uploadProgres[file.name] = new BehaviorSubject(0);
        } else {
          this.uploadProgres[file.name].next(Number(percentage));
        }
      },
      onSuccess: () => {
        const url = upload.url;
        const id = url.split('/').pop();

        this.http
          .get<{ data: any }>('files/uploaded/' + id)
          .subscribe((res: any) => {
            if (onFinish) onFinish(id, res.data);
          });

        console.log(
          'Download %s from %s',
          (upload.file as File).name,
          upload.url
        );
      },
    });

    // Check if there are any previous uploads to continue.
    upload.findPreviousUploads().then(function (previousUploads) {
      // Found previous uploads so we select the first one.
      if (previousUploads.length) {
        upload.resumeFromPreviousUpload(previousUploads[0]);
      }

      // Start the upload
      upload.start();
    });
  }

  public openUploadDialog(
    type = 'image',
    multiple = false,
    onClose?: (files: any[]) => void
  ) {
    const ref = this.dialog.open(UploadDialogComponent, {
      data: {
        type,
        multiple,
      },
    });
    ref.afterClosed().subscribe((files) => {
      console.log(files);
      if (onClose) onClose(files);
    });
  }

  public openUploadDialog$(
    type = 'image',
    multiple = false,
    options: {
      allowCrop?: boolean;
      aspectRatio?: number;
    } = {
      allowCrop: true,
      aspectRatio: 1,
    }
  ) {
    if (options.allowCrop === undefined) {
      options.allowCrop = true;
    } else if (options.aspectRatio === undefined) {
      options.aspectRatio = 1;
    }
    const ref = this.dialog.open(UploadDialogComponent, {
      data: {
        type,
        multiple,
        aspectRatio: options.aspectRatio,
        allowCrop: options.allowCrop,
      },
    });
    return ref.afterClosed();
  }
}
