<ng-container *ngIf="projects$ | async as projects">
  <div class="content container">
    <div class="d-flex justify-content-between">
      <h2>Arkadaşlarımın Katılımcı Oldukları</h2>
      <a
        [routerLink]="['/projects-extended']"
        routerLinkActive="router-link-active"
        mat-button
        color="primary"
        >Hepsini gör</a
      >
    </div>
    <div class="row">
      <div class="col-sm-6" *ngFor="let project of projects.joined">
        <project-card [project]="project"></project-card>
      </div>
    </div>
    <info-card
      icon="people"
      title="Yeni Topluluklar Bulun"
      text="Farklı projeler yapan, yetenekli öğrencilerin bulunduğu toplulukları keşfedin, bir parçası olun ve devrim yaratacak yeni bir projenin öncüsü olun."
      buttonText="Toplulukları Keşfet"
    ></info-card>
    <div class="d-flex justify-content-between mt-4">
      <h2>Arkadaşlarımdan Önerilen Projeler</h2>
      <button mat-button color="primary">Hepsini gör</button>
    </div>
    <div class="row">
      <div class="col-sm-6" *ngFor="let project of projects.suggestions">
        <project-card [project]="project"></project-card>
      </div>
    </div>
  </div>
</ng-container>
