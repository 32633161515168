import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEventType,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { HttpErrorHandlerService } from '@common/core/http/http-error-handler.service';

export class AppHttpContext {
  static prefix = 'api';

  protected httpClient: HttpClient;
  protected errorHandler: HttpErrorHandlerService;

  public formGroup: FormGroup;

  public init(httpClient: HttpClient, errorHandler: HttpErrorHandlerService) {
    this.httpClient = httpClient;
    this.errorHandler = errorHandler;
  }

  static prefixUri(uri: string) {
    if (
      uri.indexOf('://') > -1 ||
      uri.startsWith(AppHttpContext.prefix) ||
      uri.startsWith('api')
    ) {
      return uri;
    }
    return `${AppHttpContext.prefix}/${uri}`;
  }

  public get<T>(uri: string, params = {}, options: object = {}): Observable<T> {
    const httpParams = this.transformQueryParams(params);
    return this.httpClient
      .get<T>(AppHttpContext.prefixUri(uri), {
        params: httpParams,
        ...options,
      })
      .pipe(catchError((err) => this.errorHandler.handle(err, uri, options)));
  }

  public post<T>(uri: string, payload: object = null): Observable<T> {
    return this.httpClient
      .post<T>(AppHttpContext.prefixUri(uri), payload)
      .pipe(catchError((err) => this.errorHandler.handle(err, uri)))
      .pipe(
        catchError((err) => this.errorHandler.handleForm(err, this.formGroup))
      );
  }

  public put<T>(uri: string, payload: object = {}): Observable<T> {
    payload = this.spoofHttpMethod(payload, 'PUT');
    return this.httpClient
      .put<T>(AppHttpContext.prefixUri(uri), payload)
      .pipe(catchError((err) => this.errorHandler.handle(err, uri)))
      .pipe(
        catchError((err) => this.errorHandler.handleForm(err, this.formGroup))
      );
  }

  public delete<T>(uri: string, payload: object = {}): Observable<T> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: payload,
    };
    payload = this.spoofHttpMethod(payload, 'DELETE');
    return this.httpClient
      .delete<T>(AppHttpContext.prefixUri(uri), options)
      .pipe(catchError((err) => this.errorHandler.handle(err, uri)));
  }

  public postWithProgress(uri: string, params: FormData) {
    const req = new HttpRequest('POST', AppHttpContext.prefixUri(uri), params, {
      reportProgress: true,
    });
    return this.httpClient.request(req).pipe(
      catchError((err) => this.errorHandler.handle(err, uri)),
      filter((e) =>
        [
          HttpEventType.Sent,
          HttpEventType.UploadProgress,
          HttpEventType.Response,
        ].includes(e.type)
      )
    );
  }

  public transformQueryParams(params: object | null) {
    let httpParams = new HttpParams();

    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        httpParams = httpParams.append(key, value == null ? '' : value);
      });
    }

    return httpParams;
  }

  public spoofHttpMethod(
    params: object | FormData,
    method: 'PUT' | 'DELETE'
  ): object | FormData {
    if (params instanceof FormData) {
      (params as FormData).append('_method', method);
    } else {
      params['_method'] = method;
    }

    return params;
  }
}
