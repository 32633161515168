<navbar></navbar>
<div class="container" *ngIf="event$ | async as event; else loading">
  <div class="row">
    <div class="col-6">
      <a mat-button routerLink="/event/{{ eventId }}" color="accent"
        ><mat-icon>keyboard_arrow_left</mat-icon>Geri Dön</a
      >
      <div class="content">
        <h2>Devam Etmeden Önce</h2>
        <p>
          Devam etmeden önce, etkinliğe bileti almanıza rağmen bazı prosedürleri
          takip etmeniz gerekebilir. Lütfen aşağıdaki maddelere dikkatlice göz
          atın:
        </p>
        <ol>
          <li>
            Etkinliğe katıldığınızda organizatörün isteğine göre eposta
            adresiniz veya telefonunuzun paylaşılabileceğini
            <strong>unutmayın</strong>.
          </li>
          <li>
            Etkinlik süresince alınacak herhangi bir kurala uymak sizin
            sorumluluğunuzdadır. Etkinlik düzenleyicilerinin ve mekanın
            belirlediği kurallara uymak zorunludur.
          </li>
          <li>
            Kimlik veya bilet kontrolü için gerekli belgeleri etkinlik günü
            yanınızda bulundurun. Biletinizi veya rezervasyonunuzu göstermek
            için gerekli olabilir.
          </li>
          <li>
            Alkol ve/veya sigara tüketimi etkinlik mekanında yasak olabilir.
            Lütfen bu kurallara uyun ve gerektiğinde dışarıda tüketim yapın.
          </li>
          <li>
            Özel gereksinimleriniz varsa (örneğin, fiziksel engellilik),
            etkinlik düzenleyicileriyle önceden iletişime geçin. Gerekli
            düzenlemelerin yapılması için zaman tanıyın.
          </li>
          <li>
            Etkinlik sırasında acil bir durumda rehberlik ve yardım almak için
            personel ile iletişime geçin. Acil durum prosedürlerini öğrenmek
            için etkinlik mekanının güvenlik politikasını gözden geçirin.
          </li>
          <li>
            Telefonunuzun sessiz modda olduğundan emin olun. Etkinlik boyunca
            diğer katılımcıların rahatsız olmasını önlemek için lütfen sessiz
            modda tutun.
          </li>
          <li>
            Çantanızı ve değerli eşyalarınızı gözetim altında tutun. Etkinlik
            mekanında kaybolan veya çalınan eşyalar için organizatörler sorumlu
            değildir.
          </li>
          <li>
            Etkinlik sonrasında geri dönüşüm ve atık yönetimi politikalarına
            uyun. Çevreyi korumak ve etkinlik alanını temiz tutmak hepimizin
            sorumluluğudur.
          </li>
        </ol>
        <p>
          Etkinliğin keyfini çıkarırken, bu prosedürlere uymak etkinliğin
          sorunsuz ve keyifli geçmesine yardımcı olacaktır. Herhangi bir sorunuz
          veya endişeniz varsa, lütfen etkinlik düzenleyicileriyle iletişime
          geçmekten çekinmeyin.
        </p>
      </div>
    </div>
    <div class="col-2"></div>
    <div class="col-4">
      <mat-card class="event-card">
        <div class="image">
          <img [src]="event.coverImage.imagesData.variants[0]" alt="" />
        </div>
        <div class="colors">
          <div
            class="color"
            *ngFor="let color of getColors(event)"
            [style.backgroundColor]="color"
          ></div>
        </div>
        <div class="card-content">
          <h2 class="name">{{ event.name }}</h2>
          <p class="headnote">{{ event.headnote }}</p>
          <div class="creater">
            <div class="logos">
              <img
                *ngFor="let contributor of event.contributors"
                [src]="contributor.profile.avatar"
              />
            </div>
            <p>
              <b *ngFor="let contributor of event.contributors; let i = index"
                >{{
                  contributor.profile.display_name || contributor.profile.name
                }}
                <ng-container *ngIf="i < event.contributors.length - 1"
                  >,
                </ng-container></b
              >
              tarafından düzenleniyor
            </p>
          </div>
          <div class="dates">
            <div class="date">
              <mat-icon [style.color]="getAccentColor(event)"
                >calendar_month</mat-icon
              >
              <p>{{ event.startDate | dateDayMonth }}</p>
            </div>
            <div class="time">
              <mat-icon [style.color]="getAccentColor(event)"
                >schedule</mat-icon
              >
              <p>{{ event.startDate | dateHour }}</p>
            </div>
            <div class="location">
              <mat-icon [style.color]="getAccentColor(event)"
                >location_on</mat-icon
              >
              <p>{{ event.location }}</p>
            </div>
          </div>
        </div>
        <div class="price">
          <strong>{{ getApplication(event).name }}</strong> •
          {{ getApplication(event).price }}₺
        </div>
        <div class="circles">
          <div class="circle"></div>
          <div class="circle"></div>
          <div class="circle"></div>
        </div>
        <div class="footer">
          <div class="total-price">
            <h4 class="price-text">Toplam</h4>
            <h2 class="price-number">{{ getApplication(event).price }}₺</h2>
          </div>
          <div class="buy">
            <button
              mat-flat-button
              [loading]="true"
              color="primary"
              *ngIf="loading$ | async; else buy"
            >
              Tamamlanıyor...
            </button>
            <ng-template #buy>
              <button mat-flat-button color="accent" (click)="submit(event)">Ödemeyi Tamamla</button>
            </ng-template>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="loading">
    <center-loading></center-loading>
  </div>
</ng-template>
