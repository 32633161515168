<div class="header">
  <h3>Medya ve Ekler</h3>
</div>

<div class="media">
  <div class="header">
    <h3>Fotoğraf ve videolar</h3>
  </div>
  <div class="row" style="width: 100%; padding: 10px;">
    <div class="col-sm-3" *ngFor="let file of media; let i = index">
      <div class="file-container">
        <media-image
        style="background-color: rgba(0, 0, 0, 0.1); border-radius: 7px;"
        (click)="openImage(file)"
          [src]="
            file.imagesData
              ? file.imagesData.variants[0]
              : file.streamData.thumbnail
          "
          appereance="rounded"
          width="100%"
          aspectRatio="1/1"
          objectFit="cover"
          class="hover-effect"
        ></media-image>
        <div class="more" *ngIf="media.length > 4 && i === 3">
          <span>+{{ media.length - 4 }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
