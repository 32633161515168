import { Component, Input, OnInit } from '@angular/core';
import { Toast } from '@common/core/toast/toast/toast.service';
import { PollService } from '@common/services/poll.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'poll',
  templateUrl: './poll.component.html',
  styleUrls: ['./poll.component.scss'],
})
export class PollComponent implements OnInit {
  constructor(public polls: PollService, private toast: Toast) {}

  @Input() poll: any;

  public answer$ = new BehaviorSubject<any>(null);

  public vote(option: any) {
    if (this.poll.preview) {
      this.toast.open('Önizleme modunda oy kullanamazsınız.');
      return;
    }
    if (this.answer$.value.option) {
      this.poll.answerCount--;
      this.poll.options.forEach((o: any) => {
        if (o._id === this.answer$.value.option) {
          o.answerCount--;
        }
      });
    }
    this.polls.vote(this.poll._id, [option._id]).subscribe();
    this.poll.answerCount++;
    this.poll.options.forEach((o: any) => {
      if (o._id === option._id) {
        o.answerCount++;
        this.answer$.next({
          option: option._id,
        });
      }
    });
  }
  ngOnInit(): void {
    if (this.poll.preview) {
      this.answer$.next({ option: null });
      return;
    }
    this.polls.hasVoted(this.poll._id).subscribe({
      next: (response) => {
        this.answer$.next(response.data || { option: null });
      },
      error: (error) => {
        this.answer$.next({ option: null });
      },
    });
  }
}
