import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventProfileComponent } from './event-profile/event-profile.component';
import { EventProfileAboutComponent } from './event-profile/panels/event-profile-about/event-profile-about.component';
import { EventProfileApplicationsComponent } from './event-profile/panels/event-profile-applications/event-profile-applications.component';
import { EventProfileContactComponent } from './event-profile/panels/event-profile-contact/event-profile-contact.component';
import { EventProfileFeaturedContentComponent } from './event-profile/panels/event-profile-featured-content/event-profile-featured-content.component';
import { EventProfileHeaderComponent } from './event-profile/panels/event-profile-header/event-profile-header.component';
import { EventProfileParticipantsComponent } from './event-profile/panels/event-profile-participants/event-profile-participants.component';
import { EventProfileSectionsComponent } from './event-profile/panels/event-profile-sections/event-profile-sections.component';
import { EventProfileTicketsComponent } from './event-profile/panels/event-profile-tickets/event-profile-tickets.component';
import { AppCommonModule } from '@common/common.module';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { ProjectProfileComponent } from './project-profile/project-profile.component';
import { ProjectProfileHeaderComponent } from './project-profile/panels/project-profile-header/project-profile-header.component';
import { ProjectProfileAboutComponent } from './project-profile/panels/project-profile-about/project-profile-about.component';
import { ProjectProfileTimelineComponent } from './project-profile/panels/project-profile-timeline/project-profile-timeline.component';
import { ProjectProfileParticipantsComponent } from './project-profile/panels/project-profile-participants/project-profile-participants.component';
import { ProjectProfileContactComponent } from './project-profile/panels/project-profile-contact/project-profile-contact.component';
import { CompanyComponent } from './company/company.component';
import { MatTabsModule } from '@angular/material/tabs';
import { PostsModule } from '../../posts/posts.module';
import { CommuityProfileComponent } from './commuity-profile/commuity-profile.component';
import { ProjectMissionsComponent } from './project-profile/panels/project-missions/project-missions.component';
import { RouterModule } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ProfileEditingModule } from 'src/app/admin/profile-editing/profile-editing.module';
import { CompanyJobPostingsComponent } from './company/panels/company-job-postings/company-job-postings.component';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [
    EventProfileComponent,
    EventProfileHeaderComponent,
    EventProfileAboutComponent,
    EventProfileFeaturedContentComponent,
    EventProfileSectionsComponent,
    EventProfileParticipantsComponent,
    EventProfileTicketsComponent,
    EventProfileContactComponent,
    EventProfileApplicationsComponent,
    ProjectProfileComponent,
    ProjectProfileHeaderComponent,
    ProjectProfileAboutComponent,
    ProjectProfileTimelineComponent,
    ProjectProfileParticipantsComponent,
    ProjectProfileContactComponent,
    CompanyComponent,
    CommuityProfileComponent,
    ProjectMissionsComponent,
    CompanyJobPostingsComponent,
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    MatCardModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    MatBottomSheetModule,
    MatTabsModule,
    PostsModule,
    RouterModule,
    NgxSkeletonLoaderModule,
    ProfileEditingModule,
    MatMenuModule
  ],
  exports: [EventProfileComponent, ProjectProfileComponent],
})
export class ProfilesModule {}
