import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, Input } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder } from '@angular/forms';
import { MatChipInputEvent, MatChipEditedEvent } from '@angular/material/chips';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Tag } from 'src/app/models/tag';

@Component({
  selector: 'tag-input',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.scss'],
})
export class TagInputComponent {
  @Input() public tags: FormArray<AbstractControl<Tag>>;
  @Input() public width: string = '100%'; 
  @Input() public apperaance: MatFormFieldAppearance = 'outline';


  readonly separatorKeysCodes = [ENTER, COMMA] as const;


  constructor(private fb: FormBuilder) {}

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    const tag: Tag = {
      name: value,
      display_name: null,
      _id: null,
    };
    // Add our fruit
    if (value) {
      this.tags.push(this.fb.control(tag));
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  remove(tag: Tag): void {
    const index = this.tags.controls.findIndex(
      (x) => x.value.name === tag.name
    );

    if (index >= 0) {
      this.tags.controls.splice(index, 1);
      this.tags.value.splice(index, 1);
      this.tags.updateValueAndValidity();
    }
  }

  edit(tag: Tag, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove fruit if it no longer has a name
    if (!value) {
      this.remove(tag);
      return;
    }

    // Edit existing fruit
    const index = this.tags.controls.findIndex(
      (item) => item.value.name === tag.name
    );
    if (index >= 0) {
      this.tags.controls[index].value.name = value;
    }
  }
}
