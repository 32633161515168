<div
  class="mb-3 d-flex flex-column"
  style="width: 100%"
  [class.disable]="disable"
>
  <label *ngIf="label" for="input" [class.disable]="disable" class="customLabel my-3">{{
    label
  }}</label>
  <div class="input-area">
    <label for="input" class="icon">
      <mat-icon
        *ngIf="icon"
        [class.disable]="disable"
        [matTooltip]="iconTooltip"
        [matTooltipPosition]="'before'"
        >{{ icon }}</mat-icon
      >
    </label>
    <input
      type="text"
      class="customInput"
      [class.withIcon]="icon"
      name="input"
      id="input"
      aria-describedby="helpId"
      placeholder="{{ placeholder }}"
      [(ngModel)]="inputValue"
      [class.disable]="disable"
      [disabled]="disable"
      (change)="valueChanged(inputValue)"
    />
  </div>
  <small
    *ngIf="invalidState && errorDesc"
    id="helpId"
    class="form-text text-muted"
    >{{ errorDesc }}</small
  >
</div>
