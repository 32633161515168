import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@common/auth/auth.service';
import { MessageRoom, RoomType } from 'src/app/models/messages/message-room';

@Pipe({
  name: 'isRoomEditable',
})
export class IsRoomEditablePipe implements PipeTransform {
  transform(value: MessageRoom, ...args: any[]): boolean {
    if (!value) {
      return false;
    }
    return this.editable(value);
  }

  constructor(private auth: AuthService) {}

  public editable(room: MessageRoom) {
    console.log(room);
    if (room.roomType == RoomType.DIRECT_MESSAGE) {
      return false;
    }
    const member = (room?.admins || []).find(
      (member) => member.memberId == this.auth.selectedProfile._id
    );
    const isAdmin = member ? true : false;
    return isAdmin;
  }
}
