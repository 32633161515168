import { Component, Input, OnInit } from '@angular/core';
import { EventsService } from '@common/services/events.service';
import { PaginatorService } from '@common/services/paginator.service';
import { map } from 'rxjs';
import { Event } from 'src/app/models/event';

@Component({
  selector: 'event-profile-tickets',
  templateUrl: './event-profile-tickets.component.html',
  styleUrls: ['./event-profile-tickets.component.scss', '../panel.scss'],
})
export class EventProfileTicketsComponent implements OnInit {
  @Input() public event: Event;

  constructor(
    public paginator: PaginatorService,
    public events: EventsService
  ) {}

  get key() {
    return 'tickets.' + this.event._id;
  }

  ngOnInit(): void {
    this.paginator.init(this.key, (page) => {
      return this.events.getTickets(this.event._id, page).pipe(map(response => {
        return response.data.pagination
      }))
    });
  }
}
