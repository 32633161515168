import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlogService } from '@common/services/blog.service';
import { map, switchMap } from 'rxjs';

@Component({
  selector: 'blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss'],
})
export class BlogDetailsComponent {
  constructor(
    public acitvatedRoute: ActivatedRoute,
    public blogService: BlogService
  ) {}

  public blog$ = this.acitvatedRoute.params
    .pipe(
      switchMap((params) => {
        return this.blogService.getBlogPost(params['id']);
      })
    )
    .pipe(map((res) => res.data));

  public getUserBlogPosts(user: string) {
    return this.blogService.getUserPosts(user).pipe(map((res) => res.data));
  }
}
