<main-content>
  <div class="home-area">
    <div class="home-child d-flex justify-content-center">
      <div class="row" style="width: 100%">
        <div class="col-lg-8">
          <div *ngIf="!auth.loggedIn" class="logInCard">
            <h1>Daha iyi bir gelecek 🌱 inşa edenler için.</h1>
            <span
              >Kendini geliştirmenin her zaman yeni yollarını arayan öğrenciler
              ve geleceğin yıldızlarını keşfetmek isteyen profesyoneller artık
              tek bir platformda.</span
            >
            <div class="buttonContainer flex-wrap">
              <a [routerLink]="['/register']" mat-flat-button>
                Bugün Aramıza Katılın <mat-icon>arrow_forward</mat-icon>
              </a>
              <span>veya</span>
              <button mat-button>
                <mat-icon>google</mat-icon> Google ile kaydolun
              </button>
              <button mat-button>
                <mat-icon>apple</mat-icon> Apple ile kaydolun
              </button>
            </div>
          </div>
          <mat-card
            *ngIf="auth.loggedIn"
            appearance="outlined"
            class="home-panel mat-elevation-z0"
          >
            <form
              (ngSubmit)="submitPost()"
              class="center"
              [formGroup]="postForm"
            >
              <div class="post-container">
                <img
                  class="profile-image"
                  [src]="auth.selectedMember.profile.avatar"
                  alt=""
                />
                <mat-form-field appearance="fill" class="form-field">
                  <mat-label>Bu gün aklında neler var?</mat-label>
                  <input
                    formControlName="text"
                    matInput
                    placeholder="Bir şeyler yaz..."
                  />
                  <mat-icon matSuffix>mood</mat-icon>
                  <mat-hint
                    >{{
                      auth.selectedMember.profile.display_name ||
                        auth.selectedMember.profile.name
                    }}
                    olarak paylaşıyorsun...</mat-hint
                  >
                </mat-form-field>
              </div>
              <div class="" style="margin: 2px">
                <div
                  class="d-flex flex-wrap justify-content-sm-center"
                  style="
                    align-items: center;
                    justify-content: flex-start;
                    padding-left: 73px;
                    padding-right: 16px;
                  "
                >
                  <input
                    (change)="upload(file.files)"
                    type="file"
                    style="display: none"
                    #file
                  />
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    class="attachment-button"
                    (click)="pickImage()"
                  >
                    <mat-icon>image</mat-icon> Fotoğraf
                  </button>

                  <button
                    mat-button
                    type="button"
                    style="color: #f76707"
                    class="attachment-button"
                    (click)="pickVideo()"
                  >
                    <mat-icon>videocam</mat-icon> Video
                  </button>
                  <button
                    mat-button
                    type="button"
                    style="color: #0ca678"
                    class="attachment-button"
                    (click)="contentEditing.addPoll()"
                  >
                    <mat-icon>poll</mat-icon> Anket
                  </button>
                  <button
                    mat-button
                    type="button"
                    style="color: #f03e3e"
                    class="attachment-button"
                    [routerLink]="['/admin/blog-center']"
                  >
                    <mat-icon>event</mat-icon> Blog
                  </button>
                  <div style="flex: 1 1 auto"></div>
                  <span *ngIf="images.controls.length + videos.controls.length"
                    >{{ images.controls.length + videos.controls.length }} dosya
                    seçili</span
                  >

                  <button type="submit" mat-flat-button color="primary">
                    <span>Gönder</span>&nbsp;&nbsp;&nbsp;<mat-icon
                      >send</mat-icon
                    >
                  </button>
                </div>
              </div>
              <br />
            </form>
          </mat-card>
          <br />

          <ng-container *ngIf="posts$ | async as posts; else loadingPosts">
            <post
              *ngFor="let post of posts.data"
              [post]="post"
              inViewport
              (inViewportAction)="onIntersection($event, post)"
            ></post>
          </ng-container>
          <ng-template #loadingPosts>
            <div class="loading" style="width: 100%;">
              <mat-card *ngFor="let _ of [].constructor(5)" style=" margin-top: 20px; padding: 10px;">
                <div
                  class="d-flex"
                  style="gap: 20px; width: 100%; margin-top: 10px"
                >
                  <ngx-skeleton-loader
                    [theme]="{ height: '50px', width: '50px' }"
                    appearance="circle"
                  ></ngx-skeleton-loader>
                  <div class="d-flex flex-column" style="width: 100%">
                    <ngx-skeleton-loader
                      style="width: 100%"
                      [theme]="{ height: '30px', width: '100%' }"
                    ></ngx-skeleton-loader>
                    <ngx-skeleton-loader
                      style="width: 100%"
                      [theme]="{ height: '30px', width: '100%' }"
                    ></ngx-skeleton-loader>
                    <ngx-skeleton-loader
                      style="width: 100%"
                      [theme]="{ height: '100px', width: '100%' }"
                    ></ngx-skeleton-loader>
                  </div>
                </div>
              </mat-card>
            </div>
          </ng-template>
        </div>
        <div class="col-lg-4">
          <div class="mb-3">
            <summary></summary>
          </div>
          <div class="mb-3">
            <youmightknow></youmightknow>
          </div>
          <ng-container *ngIf="!auth.loggedIn">
            <mat-card class="joinUs">
              <div class="icon-frame">
                <div class="icon-frame-1">
                  <div class="icon-frame-2">
                    <mat-icon>auto_awesome</mat-icon>
                  </div>
                </div>
              </div>
              <div class="join-header">
                <h2>Unilocked Topluluğuna Katılmak İçin Harika Bir Gün!</h2>
              </div>
              <div class="join-text">
                <p>
                  Alanında uzman profesyoneller ve geleceğin uzmanları
                  öğrenciler tek bir platformda! Aramıza bugün katılın.
                </p>
              </div>
              <div class="d-flex justify-content-center">
                <a
                  [routerLink]="['/register']"
                  mat-flat-button
                  class="button-other"
                >
                  Bugün Aramıza Katılın <mat-icon>arrow_forward</mat-icon>
                </a>
              </div>
            </mat-card>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</main-content>
