<mat-card appearance="outlined" class="company mat-elevation-z0 hover-effect-card" [routerLink]="['/company',company._id]">
  <div class="backdrop-area" style="position: relative; height: 120px">
    <div
      style="position: absolute"
      l
      class="backdrop"
      [style.backgroundImage]="'url(' + company.backdrop + ')'"
    ></div>
    <div
      style="
        width: 100%;
        height: 120px;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 12px 12px 0 0;
      "
    ></div>
    <img class="avatar" [src]="company.avatar" alt="Avatar" />
  </div>
  <h3 class="name">{{ company.name }}</h3>
  <p class="short-desc">{{ company.sector }} Şirketi</p>
  <p class="role">{{company.followerCount}} Takipçi</p>
</mat-card>
