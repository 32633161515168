<mat-card class="card">
  <mat-card-title class="title">Proje</mat-card-title>

  <div class="card-container">
    <div class="card-content">
      <div class="card-image-container">
        <ng-container *ngIf="data?.coverImage; else defaultContent">
          <img [src]="data.coverImage | imageFile" alt="Görsel yüklenemedi" />
        </ng-container>
        <ng-template #defaultContent>
          <div class="no-image">Görsel yok</div>
        </ng-template>
        <div class="card-text-header-headertext" [routerLink]="['/project', data._id]">{{ data.name }}</div>
      </div>
      <div class="card-image-inner-image">
        <img [src]="data?.owner?.avatar || 'assets/default-avatar.png'" alt="Card Image" class="image" />
        <div class="card-text-header-subtitle">{{ data?.owner?.name }}</div>
      </div>
    </div>
  </div>

  <mat-card-content class="content">
    <main-text-card></main-text-card>
  </mat-card-content>
  <like-save-card [data]="getMappedData()"></like-save-card>
</mat-card>
