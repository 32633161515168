import { map, pipe } from 'rxjs';
import { AppHttpClient } from '@common/core/http/http.service';
import { Injectable } from '@angular/core';
import { StandartResponse } from '@common/core/http/standart-response';
import { Pagination } from 'src/app/models/pagination';
import { Post } from 'src/app/models/post';
import { AuthService } from '@common/auth/auth.service';

const BASE = 'posts';

@Injectable({
  providedIn: 'root',
})
export class PostsService {
  constructor(
    public httpClient: AppHttpClient,
    private authService: AuthService
  ) {}

  public reportView(post: Post) {
    return this.httpClient.post<StandartResponse<Post>>(
      `posts/${post._id}/report-view`
    );
  }

  public get(id: string) {
    return this.httpClient
      .get<StandartResponse<Post>>(`posts/${id}`)
      .pipe(map((response) => response.data));
  }

  public getSavedPosts() {
    return this.httpClient
      .get<StandartResponse<Post[]>>('posts/saved/posts')
      .pipe(map((response) => response.data));
  }

  public savePost(post: Post) {
    return this.httpClient
      .post<StandartResponse<Post>>(`posts/${post._id}/save`)
      .pipe(map((response) => response.data));
  }

  public unsavePost(post: Post) {
    return this.httpClient
      .post<StandartResponse<Post>>(`posts/${post._id}/deletebookmark`)
      .pipe(map((response) => response.data));
  }

  public getPosts() {
    return this.httpClient
      .get<StandartResponse<Pagination<Post>>>('posts')
      .pipe(map((response) => response.data));
  }

  addReaction(post: Post, reaction: string) {
    return this.httpClient
      .post<StandartResponse<Post>>(`posts/${post._id}/${reaction}`)
      .pipe(map((response) => response.data));
  }

  public isSaved(post: Post) {
    return (
      this.authService.loggedIn &&
      this.authService.user.postsSaved.includes(post)
    );
  }

  public getReplies(id: string, page: number) {
    return this.httpClient.get<StandartResponse<Pagination<Post>>>(
      `${BASE}/${id}/replies`,
      {
        perPage: 20,
        page,
      }
    );
  }

  public weekAnalytics(startDate: Date) {
    return this.httpClient.post<StandartResponse<any>>(`posts/analytics-week`, {
      startDate,
    });
  }

  public calendarList() {
    return this.httpClient.get<
      StandartResponse<
        {
          date: string;
          items: Post[];
        }[]
      >
    >('posts/calendar-list');
  }

  public calendarMonth(startDate: string) {
    return this.httpClient.post<
      StandartResponse<
        {
          date: Date;
          items: Post[];
        }[]
      >
    >('posts/calendar-month', {
      startDate,
    });
  }

  public calendarWeek(startDate: string) {
    return this.httpClient.post<
      StandartResponse<
        {
          date: string;
          items: Post[];
        }[]
      >
    >('posts/calendar-week', {
      startDate,
    });
  }

  delete(_id: string) {
    return this.httpClient.delete<StandartResponse<Post>>(`${BASE}/${_id}`);
  }

  public replyPost(postId: string, data: any) {
    return this.httpClient.post<StandartResponse<Post>>(
      `${BASE}/${postId}/reply`,
      data
    );
  }
}
