import { StandartResponse } from './../core/http/standart-response';
import { Injectable } from '@angular/core';
import { AppHttpClient } from '@common/core/http/http.service';
import { ucFirst } from '@common/core/utils/uc-first';
import { map } from 'rxjs';
import { User } from 'src/app/models/user';
import { Profile } from 'src/app/models/profile';
import { Pagination } from 'src/app/models/pagination';
import { File } from 'src/app/models/file';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private http: AppHttpClient) {}

  public getUserProfile(username: string) {
    return this.http
      .get<StandartResponse<Profile>>('user-profile/' + username)
      .pipe(
        map((response) => {
          const data = response.data;
          data.headnote = data.headnote || '';
          data.info = '500+ Bağlantı • Ankara, Türkiye';
          data.projects = {
            completed: data.projects,
            inProgress: data.projects,
          };
          return data;
        })
      );
  }

  updateAvatarAndAbout(data: {
    _id: string;
    avatar: string;
    avatarFile: File;
    about: string;
    type: string;
  }) {
    return this.http.put(
      'profiles/' + data.type + '/' + data._id + '/avatar',
      data
    );
  }

  public getProfile<T extends Profile>(profileType: string, profileId: string) {
    profileType = ucFirst(profileType);

    return this.http.get<StandartResponse<T>>(
      `profiles/${profileType}/${profileId}`
    );
  }

  public updateProfile(profileType: string, profileId: string, data: any) {
    profileType = ucFirst(profileType);
    return this.http.put(`profiles/${profileType}/${profileId}`, data);
  }

  public findExternalUsers(page: number) {
    return this.http.get<StandartResponse<Pagination<User>>>(
      'profiles/find-external-users',
      {
        params: {
          page: page.toString(),
        },
      }
    );
  }

  create(data: any) {
    return this.http.post<StandartResponse<Profile>>('profiles', data);
  }
}
