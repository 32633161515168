<mat-card class="poll-card" *ngIf="answer$ | async as answer">
  <div class="poll">
    <div class="question">
      <h3>{{ poll.question }}</h3>
    </div>
    <div class="options">
      <div
        class="option"
        *ngFor="let option of poll.options"
        (click)="vote(option)"
      >
        <div class="overlay d-flex flex-sm-wrap">
          <div class="option-text">
            <p>{{ option.option }}</p>
          </div>
          <div class="option-votes">
            <p>{{ option.answerCount }} Yanıt</p>
          </div>
        </div>
        <div class="option-bar">
          <div
            class="bar"
            [class.answered]="answer.option === option._id"
            [style.width.%]="
              poll.answerCount > 0
                ? (option.answerCount / poll.answerCount) * 100
                : 0
            "
          ></div>
        </div>
      </div>
    </div>
  </div>
</mat-card>
