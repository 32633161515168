<img
  [class.circle]="appereance == 'circle'"
  [class.default]="appereance == 'default'"
  [class.rounded]="appereance == 'rounded'"
  [src]="isFile() ? (getSrcAsFile() | imageFile) : src"
  lazy
  [alt]="alt"
  [loading]="'lazy'"
  [style]="{ width: width, height: height, aspectRatio: aspectRatio, objectFit: objectFit}"
  (error)="onImgError($event)"
/>
