import { Dialog } from '@angular/cdk/dialog';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@common/auth/auth.service';
import { Event } from 'src/app/models/event';
import { ApplyEventDialogComponent } from '../apply-event-dialog/apply-event-dialog.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Component({
  selector: 'event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss'],
})
export class EventCardComponent implements OnInit {
  @Input('event') event: Event;

  @Input('icon') icon: string;
  @Input('color') color: string;

  @Input('title') title: string;
  @Input('text') text: string;
  @Input('buttonText') buttonText: string;
  @Output('buttonClick') buttonClick = new EventEmitter();
  @Input('fullHeight') fullHeight: boolean = false;

  constructor(public auth: AuthService, public dialog: MatDialog,public bottomSheet:MatBottomSheet) {}

  ngOnInit(): void {}

  public get hasTicket() {
    return this.event?.tickets?.length > 0;
  }

  public buyTicket() {
    this.bottomSheet.open(ApplyEventDialogComponent, {
      data: {
        event: this.event,
      },
    });
  }

  click() {
    this.buttonClick.emit();
  }
}
