import { Component, Input } from '@angular/core';
import { File } from 'src/app/models/file';

@Component({
  selector: 'media-image',
  templateUrl: './media-image.component.html',
  styleUrls: ['./media-image.component.scss']
})
export class MediaImageComponent {

  @Input() public lazy: boolean = true;
  @Input() public alt: string = '';
  @Input() public src: string | File;
  @Input() public width: string = '100%';
  @Input() public height: string = 'auto';
  @Input() public objectFit: string = 'contain';
  @Input() public appereance: 'circle' | 'default' | 'rounded' = 'rounded';
  @Input() public aspectRatio: string = 'none';

 onImgError(event) {
    event.target.src = 'assets/img/default.png';
 }


 public isFile(): boolean {
    return !(typeof this.src === 'string');
 }

 public getSrcAsFile(): File {
    return this.src as File;
 }

}
