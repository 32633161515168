<main-content>
    <div class="container">
        <ng-container *ngIf="data$ | async as data">
            <div class="featured">
                <h1>Günün Öne Çıkanları</h1>
                <slider>
                    <slider-item>
                        <blog-card *ngFor="let blog of data.featured" [blog]="blog"></blog-card>
                    </slider-item>
                </slider>
            </div>
            <div class="suggestions">
                <h1>Öneriler</h1>
                <p class="sub">Sevebileceğinizi düşündüğümüz blog gönderileri</p>
                <div class="row">
                    <div class="col-sm-4" *ngFor="let blog of data.suggestions">
                        <blog-card [blog]="blog"></blog-card>
                    </div>
                </div>
            </div>
            <div class="latest">
                <h1>En Yeniler</h1>
                <p class="sub">Sevebileceğinizi düşündüğümüz taze blog gönderileri</p>
                <div class="row">
                    <div class="col-sm-4" *ngFor="let blog of data.latest">
                        <blog-card [blog]="blog"></blog-card>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</main-content>