import { Component, Input } from '@angular/core';
import { DiscoveryCatalogService } from '@common/services/discovery-catalog.service';
import { Company } from 'src/app/models/company';

@Component({
  selector: 'company-card',
  templateUrl: './company-card.component.html',
  styleUrls: ['./company-card.component.scss'],
})
export class CompanyCardComponent {
  @Input() data: Company;

  constructor(private discoveryService: DiscoveryCatalogService) {}

  public getMappedData() {
    return {
      type: 'Company',
      id: this.data._id,
    };
  }
}
