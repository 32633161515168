import { AfterViewInit, Component, Input } from '@angular/core';
import * as Plyr from 'plyr';
import { File } from 'src/app/models/file';

declare var dashjs: any;

@Component({
  selector: 'video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements AfterViewInit {
  @Input() public video: File;
  @Input() public lazy: boolean = true;

  public player: Plyr;

  public get videoElement(): HTMLVideoElement | null {
    return document.querySelector('video');
  }

  ngAfterViewInit(): void {
    this.lazyInitDash();
  }

  public lazyInitDash() {
    const url = 'https://cdn.dashjs.org/latest/dash.all.min.js';
    const script = document.createElement('script');
    script.src = url;
    script.onload = () => {
      this.initDash();
    };
    document.head.appendChild(script);
  }

  public initDash() {
    if (!this.video?.streamData) {
      return;
    }
    const player = dashjs.MediaPlayer().create();
    player.initialize(
      document.querySelector('#vidd'),
      this.video.streamData.playback.dash,
      true
    );
    this.player = new Plyr('#vidd', {
      disableContextMenu: true,
      muted: true,
      i18n: {
        quality: 'Kalite',
        speed: 'Hız',
      },
      keyboard: {
        focused: true,
        global: true,
      },
    });
  }

  public onIntersection({
    target,
    visible,
  }: {
    target: Element;
    visible: boolean;
  }): void {
    if (visible && this.lazy) {
      this.videoElement?.play().catch((e) => {});
    } else {
      this.videoElement?.pause();
    }
  }
}
