<mat-card appearance="outlined" class="post-card mat-elevation-z0">
  <div class="post-container">
    <div style="width: 100%">
      <div class="d-flex justify-content-between">
        <div class="post-top">
          <div class="image-container">
            <img class="profile-image" [src]="post.postedBy?.avatar" alt="" />
          </div>
          <div class="info-container">
            <h4 [routerLink]="getLink()" class="user-name">
              {{ post.postedBy.display_name || post.postedBy.name }}
            </h4>
            <p class="user-info">{{ post.postedBy.headnote }}</p>
            <p class="post-info">
              {{ post.createdAt | dateAgo }} • Düzenlendi
              •&nbsp;<mat-icon>public</mat-icon>&nbsp;Herkese Açık
            </p>
          </div>
        </div>
        <button
          mat-icon-button
          *ngIf="auth.loggedIn && post.postedBy._id === auth.selectedMember.profileId"
          [matMenuTriggerFor]="menu"
        >
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="deletePost()">Sil</button>
        </mat-menu>
      </div>
      <div class="post-content">
        <post-content [content]="post.text"> </post-content>
      </div>
      <div class="post-poll" *ngIf="post.poll">
        <poll [poll]="post.poll"></poll>
      </div>
      <div class="post-image row">
        <div class="col" *ngFor="let image of post.images">
          <img [src]="image | imageFile" alt="Post image" />
        </div>
        <div class="col" *ngFor="let video of post.videos">
          <post-video [video]="video"></post-video>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="buttons-container">
        <div class="d-flex buttons flex-sm-wrap">
          <ng-container *ngIf="isMobile$ | async; else desktop">
            <button
              mat-icon-button
              (click)="addReaction('like')"
              [color]="alreadyReacted('like') ? 'primary' : 'secondary'"
            >
              <mat-icon>thumb_up</mat-icon>
            </button>
            <button
              mat-icon-button
              *ngIf="showCommentButton && !post.replyTo"
              (click)="toggleComments($event)"
            >
              <mat-icon>chat_bubble</mat-icon>
            </button>
            <button mat-icon-button (click)="share()">
              <mat-icon>ios_share</mat-icon>
            </button>
            <ng-container *ngIf="auth.loggedIn">
              <button
                mat-icon-button
                color="primary"
                (click)="unsavePost()"
                *ngIf="isSaved; else save1"
              >
                <mat-icon>bookmark</mat-icon>
              </button>
              <ng-template #save1>
                <button mat-icon-button (click)="savePost()">
                  <mat-icon>bookmark</mat-icon>
                </button>
              </ng-template>
            </ng-container>
          </ng-container>
          <ng-template #desktop>
            <button
              [disabled]="!auth.loggedIn"
              (click)="addReaction('like')"
              [color]="alreadyReacted('like') ? 'primary' : 'secondary'"
              mat-button
              class="attachment-button"
            >
              <mat-icon>thumb_up</mat-icon>Beğen

              <div
                class="count"
                [ngStyle]="{
                  'background-color': alreadyReacted('like')
                    ? '#4b53f2'
                    : 'var(--text)'
                }"
              >
                {{ post.reactionsCounts?.like || 0 }}
              </div>
            </button>
            <button
              [disabled]="!auth.loggedIn"
              *ngIf="showCommentButton && !post.replyTo"
              mat-button
              class="attachment-button"
              (click)="toggleComments($event)"
            >
              <mat-icon>chat_bubble</mat-icon>Yorum Yap
              <div class="count">{{ post.commentCount || 0 }}</div>
            </button>
            <button mat-button class="attachment-button" (click)="share()">
              <mat-icon>ios_share</mat-icon>Paylaş
            </button>
            <ng-container *ngIf="auth.loggedIn">
              <button
                mat-button
                color="primary"
                class="attachment-button"
                (click)="unsavePost()"
                *ngIf="isSaved; else save"
              >
                <mat-icon>bookmark</mat-icon> Kaydedildi
              </button>
              <ng-template #save>
                <button
                  mat-button
                  (click)="savePost()"
                  class="attachment-button"
                >
                  <mat-icon>bookmark</mat-icon> Kaydet
                </button>
              </ng-template>
            </ng-container>
          </ng-template>
        </div>
      </div>
      <mat-divider
        *ngIf="!auth.loggedIn"
        style="margin-top: 10px"
      ></mat-divider>
      <div *ngIf="!auth.loggedIn" class="createAccountBand">
        <div class="offerBanner">
          <div class="infoContainer">
            <span class="header">Sınırsız Keşif</span>
            <span
              >Böyle daha fazla gönderi görmek için Unilocked üyesi olun</span
            >
          </div>
          <div class="createAccountButtonContainer">
            <a
              [routerLink]="['/register']"
              type="button"
              mat-flat-button
              color="primary"
            >
              Bugün Aramıza Katılın
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>

<div>
  <div class="replies" *ngIf="showReplies">
    <post-replies [post]="post"></post-replies>
  </div>
</div>
