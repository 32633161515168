<ng-container *ngIf="receiver">
  <div class="header">
    <h3>Kişi Bilgisi</h3>
  </div>
  <div class="member-container">
    <div class="background-container">
      <media-image
        appereance="default"
        width="100%"
        height="auto"
        aspectRatio="16/3"
        [src]="receiver?.member.backdrop"
      ></media-image>
    </div>
    <div class="details-container">
      <div class="avatar">
        <media-image
          appereance="circle"
          width="40px"
          height="40px"
          objectFit="cover"
          [src]="receiver?.member.avatar"
        ></media-image>
      </div>
      <div class="name-headnote">
        <h4>{{ receiver?.member.display_name }}</h4>
        <p>{{ receiver?.member.headnote }}</p>
      </div>
    </div>
  </div>
</ng-container>
