import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from '@common/auth/auth.service';
import { Toast } from '@common/core/toast/toast/toast.service';
import { ProjectsService } from '@common/services/projects.service';
import { Project } from 'src/app/models/project';

@Component({
  selector: 'project-profile-header',
  templateUrl: './project-profile-header.component.html',
  styleUrls: ['./project-profile-header.component.scss', '../panel.scss'],
})
export class ProjectProfileHeaderComponent {
  constructor(
    public projects: ProjectsService,
    public auth: AuthService,
    private toast: Toast
  ) {}

  @Input() public project: Project;
  @Output() activeStep: EventEmitter<number> = new EventEmitter();
  _activeStep: number = 0;
  public convertProjectStatus(status: string) {
    switch (status) {
      case 'active':
        return 'Aktif';
      case 'completed':
        return 'Tamamlandı';
    }
    return 'Tamamlandı';
  }

  activeStepCounter(activeStep: number) {
    this._activeStep = activeStep;
    this.activeStep.emit(activeStep);
  }

  public getButtonText() {
    const participantUser = this.project.participants.find(
      (participant) => participant.userId === this.auth.user._id
    );
    if (participantUser) {
      return 'Katılımcısınız';
    }
    if (this.project.currentApplication) {
      return 'Başvurunuz alındı';
    }
    const isProjectEnded = this.project.endDate < new Date();
    if (isProjectEnded) {
      return 'Proje sona erdi';
    }
    return 'Projeye Katıl';
  }

  public isButtonDisabled() {
    const participantUser = this.project.participants.find(
      (participant) => participant.userId === this.auth.user._id
    );
    if (participantUser) {
      return true;
    }
    if (this.project.currentApplication) {
      return true;
    }
    const isProjectEnded = this.project.endDate < new Date();
    if (isProjectEnded) {
      return true;
    }
    return false;
  }

  public buttonClick() {
    if (this.isButtonDisabled()) {
      return;
    }
    this.projects.addApplication(this.project._id).subscribe((res) => {
      this.project.currentApplication = res.data;
      this.toast.open('Başvurunuz alındı');
    });
  }

  public copyProfileLink() {
    const url = window.location.href;
    navigator.clipboard.writeText(url).then(() => {
      this.toast.open('Proje linki kopyalandı');
    });
  }
}
