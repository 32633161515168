import { Component, HostBinding, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '@common/auth/auth.service';
import { BootstrapDataService } from '@common/core/bootstrap/bootstrap-data.service';
import { MetaTagsService } from '@common/core/meta/meta-tags.service';
import { SettingsService } from '@common/core/settings/settings.service';
import { AppThemeService } from '@common/ui/theming/app-theme.service';
import { BehaviorSubject, catchError, finalize, throwError } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'unista';
  public loadingBootstrap$ = new BehaviorSubject(true);
  public bootstrapError$ = new BehaviorSubject<any>(null);

  constructor(
    public auth: AuthService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    public appTheme: AppThemeService,
    public bootstrapData: BootstrapDataService,
    public settings: SettingsService,
    public metaTagsService:MetaTagsService
  ) {
    iconRegistry.addSvgIcon(
      'google',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/google-logo.svg')
    );
    iconRegistry.addSvgIcon(
      'settings_alert',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/settings_alert.svg')
    );
    this.auth.init(location.pathname.replace('login', 'home') || 'home');
  }

  @HostBinding('class')
  get themeMode(): string {
    return this.appTheme.darkMode ? 'dark-theme' : 'light-theme';
  }

  ngOnInit() {
    this.appTheme.changed$.subscribe((darkMode) => {
      if (darkMode) {
        document.body.classList.add('dark-theme');
        document.body.classList.remove('light-theme');
        document.body.style.backgroundColor = '#121212';
      } else {
        document.body.classList.remove('dark-theme');
        document.body.classList.add('light-theme');
      }
    });
    this.metaTagsService.init();
    this.bootstrapData
      .init()
      .pipe(
        catchError((error) => {
          error.message = 'Failed to load bootstrap data';
          this.bootstrapError$.next(error);
          return throwError(() => error);
        })
      )
      .pipe(finalize(() => this.loadingBootstrap$.next(false)))
      .subscribe((data) => {
        if (data.success) {
          this.settings.settings = data.data.settings;
          this.auth._members = data.data.members;
          if (data.data.user) {
            this.auth.handleLogin(
              {
                success: true,
                user: data.data.user,
              },
              false
            );
            this.auth.followedIds = data.data.followedIds;
          } else {
            this.auth.loading$.next(false);
          }
          this.loadingBootstrap$.next(false);
          this.auth.loadingStatus$.next(false);
          this.auth.unreadNotifications = data.data.unreadNotifications;

          if (this.appTheme.darkMode) {
            document.body.classList.add('dark-theme');
            document.body.classList.remove('light-theme');
            document.body.style.backgroundColor = '#121212';
          } else {
            document.body.classList.remove('dark-theme');
            document.body.classList.add('light-theme');
          }
        }
      });
  }
}
