import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SiteModule } from './site/site.module';
import { AppCommonModule } from '@common/common.module';
import { AuthModule } from '@common/auth/auth.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastModule } from '@common/core/toast/toast/toast.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ButtonService } from '@common/services/button.service';
import { ErrorPageModule } from '@common/ui/error-page/error-page.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular'; // Import the Sentry module
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlCro } from '@common/ui/paginator/mat-paginator-intl-cro';
import { MetaTagsInterceptor } from '@common/core/meta/meta-tags-interceptor';

const config: SocketIoConfig = {
  url: '/',
  options: {
    transports: ['websocket'],
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SiteModule,
    AuthModule,
    HttpClientModule,
    ToastModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    ErrorPageModule,
    SocketIoModule.forRoot(config),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro },
    ButtonService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MetaTagsInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
