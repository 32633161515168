import { Component } from '@angular/core';
import { MessagingService } from '../../messaging.service';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { UploadService } from '@common/core/upload/upload.service';

@Component({
  selector: 'write-message',
  templateUrl: './write-message.component.html',
  styleUrls: ['./write-message.component.scss'],
})
export class WriteMessageComponent {
  constructor(
    public messaging: MessagingService,
    private fb: FormBuilder,
    public uploadService: UploadService
  ) {}

  public form = this.fb.group({
    message: [''],
    files: this.fb.array([]),
  });

  public get files() {
    return this.form.get('files') as FormArray;
  }

  public submit() {
    this.messaging.sendMessage(
      this.messaging.currentRoom$.value._id,
      this.messaging.currentRoom$.value.receiver,
      this.form.value
    );
    console.log(this.form.value);
  }

  public addFile(type:string) {
    this.uploadService.openUploadDialog(type, true, (files) => {
      if (!files) return;
      for (const file of files) {
        this.files.push(this.fb.control(file));
      }
    });
  }

  public clearFiles() {
    this.files.clear();
  }
}
