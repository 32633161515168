import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { HttpErrorHandlerService } from '@common/core/http/http-error-handler.service';
import { AppHttpContext } from './http-context';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class AppHttpClient {
  static prefix = 'api';
  public defaultContext = new AppHttpContext();

  constructor(
    protected httpClient: HttpClient,
    protected errorHandler: HttpErrorHandlerService
  ) {
    this.defaultContext.init(httpClient, errorHandler);
  }

  static prefixUri(uri: string) {
    if (
      uri.indexOf('://') > -1 ||
      uri.startsWith(AppHttpClient.prefix) ||
      uri.startsWith('api')
    ) {
      return uri;
    }
    return `${AppHttpClient.prefix}/${uri}`;
  }

  public withContext(context: AppHttpContext, formGroup?: FormGroup) {
    context.init(this.httpClient, this.errorHandler);
    if (formGroup) {
      context.formGroup = formGroup;
    }
    return context;
  }

  public withForm(formGroup: FormGroup) {
    return this.withContext(new AppHttpContext(), formGroup);
  }

  public get<T>(uri: string, params = {}, options: object = {}): Observable<T> {
    return this.defaultContext.get<T>(uri, params, options);
  }

  public post<T>(uri: string, payload: object = null): Observable<T> {
    return this.defaultContext.post<T>(uri, payload);
  }

  public put<T>(uri: string, payload: object = {}): Observable<T> {
    return this.defaultContext.put<T>(uri, payload);
  }

  public delete<T>(uri: string, payload: object = {}): Observable<T> {
    return this.defaultContext.delete<T>(uri, payload);
  }

  public postWithProgress(uri: string, params: FormData) {
    return this.defaultContext.postWithProgress(uri, params);
  }

  protected transformQueryParams(params: object | null) {
    return this.defaultContext.transformQueryParams(params);
  }

  protected spoofHttpMethod(
    params: object | FormData,
    method: 'PUT' | 'DELETE'
  ): object | FormData {
    return this.defaultContext.spoofHttpMethod(params, method);
  }
}
