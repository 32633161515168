<mat-card class="post-card hover-effect-card" [ngClass]="getStatusColor()" [routerLink]="['/event',item._id]">
    <div class="title d-flex justify-content-between align-items-center">
      <span class="time d-flex align-items-center">
        <mat-icon> {{getStatusIcon()}} </mat-icon>
        {{item.startDate | dateHour}}
      </span>
      
    </div>
    <div class="subtitle">
      {{getStatusText()}}<br />
      <span [innerHTML]="item.name"></span>
    </div>
  </mat-card>
  