import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '@common/auth/auth.service';
import { Toast } from '@common/core/toast/toast/toast.service';

@Component({
  selector: 'invite-aditional-details',
  templateUrl: './invite-aditional-details.component.html',
  styleUrls: ['./invite-aditional-details.component.scss'],
})
export class InviteAditionalDetailsComponent {
  constructor(
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      email: string;
    },
    public auth: AuthService,
    public toast: Toast,
    public ref: MatDialogRef<InviteAditionalDetailsComponent>
  ) {}

  public form = this.fb.group({
    email: [this.data.email, [Validators.required, Validators.email]],
    name: ['', [Validators.required]],
    source: ['', [Validators.required]],
    organization: ['', [Validators.required]],
  });

  public canSubmit() {
    return this.form.valid;
  }

  public submit() {
    this.auth.joinBeta(this.form.value).subscribe((data) => {
      if (data.success) {
        this.toast.open(
          'Yakında Unilocked davet kodunu içeren bir eposta alacaksın. Aramıza şimdiden hoşgeldin! '
        );
        this.ref.close();
      }
    });
  }
}
