<mat-card class="card">
  <div style="padding: 10px">
    <h2>Kişi</h2>

    <div class="d-flex gap-10">
      <media-image
        [src]="data.avatar"
        appereance="circle"
        width="60px"
        height="60px"
        objectFit="fill"
      ></media-image>
      <div>
        <h3 class="name clickable" [routerLink]="['/user-profile', data.name]">{{ data.display_name }}</h3>
        <p class="sub-hedaer">{{ data.headnote }}</p>
        <p class="sub-hedaer">{{ data.followerCount || 0 }} Takipçi</p>
      </div>
    </div>
  </div>

  <mat-card-content class="content">
    <main-text-card></main-text-card>
  </mat-card-content>
  <like-save-card [data]="getMappedData()"></like-save-card>
</mat-card>
