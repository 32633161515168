<div class="container">
  <div class="info">
    <info-card
      *ngIf="!isMe"
      icon="notifications"
      [title]="company.name + ' Tarafından İş İlanları Uyarısı Alın'"
      text="İlginizi çekebilecek ilanlar yayınlandığında bilgi alacaksınız."
      [buttonText]="(following$ | async) ? 'Takibi Bırak' : 'Takip Et'"
      (buttonClick)="toggle()"
    ></info-card>
  </div>
  <div class="jobs card" *ngIf="jobPostings.length">
    <div class="title">
      <h2>Yeni İş İlanları</h2>
    </div>
    <div class="jobs-area">
      <div class="row">
        <div
          class="col-6 hover-effect"
          *ngFor="let job of jobPostings"
          [routerLink]="['/job-posting', job._id]"
        >
          <div class="logo">
            <media-image
              appereance="circle"
              width="48px"
              height="48px"
              [src]="job.creator.avatar"
            ></media-image>
          </div>
          <div class="text">
            <h3>{{ job.title }}</h3>
            <p>{{ job.locations.join(", ") }} <ng-container *ngIf="job.creatorId != company._id"> • {{job.creator.display_name}} Şirketinde</ng-container></p>
            <p>{{ job.viewStartDate | dateAgo }} Yayınlandı</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-card appearance="outlined" class="employees card">
    <div class="title">
      <h2>Çalışanlar</h2>
    </div>
    <div class="subtitle">
      <p>
        Bir ilana başvuramadan önce aynı şirkette çalışan insanlara danışın.
      </p>
    </div>
    <div class="employees-area">
      <div class="row justify-content-between">
        <div
          class="col-6 hover-effect"
          *ngFor="let member of members"
          [routerLink]="['/user-profile', member.member.name]"
        >
          <div class="logo">
            <media-image
              appereance="circle"
              width="48px"
              height="48px"
              [src]="member.member.avatar"
            ></media-image>
          </div>
          <div class="text">
            <h3>{{ member.member.display_name }}</h3>
            <p>{{ member.member.headnote }}</p>
            <p></p>
            <follow-button
              [profile]="member.member"
              type="User"
            ></follow-button>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
  <div class="jobs card" *ngIf="jobPostings.length">
    <div class="title">
      <h2>Tüm İş İlanları</h2>
      <paginator [key]="key"></paginator>
    </div>
    <div class="jobs-area">
      <div class="row">
        <div
          class="col-6 hover-effect"
          *ngFor="let job of paginatorService.datas.get(key)"
          [routerLink]="['/job-posting', job._id]"
        >
          <div class="logo">
            <media-image
              appereance="circle"
              width="48px"
              height="48px"
              [src]="job.creator.avatar"
            ></media-image>
          </div>
          <div class="text">
            <h3>{{ job.title }}</h3>
            <p>{{ job.locations.join(", ") }} <ng-container *ngIf="job.creatorId != company._id"> • {{job.creator.display_name}} Şirketinde</ng-container></p>
            <p>{{ job.viewStartDate | dateAgo }} Yayınlandı</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-card appearance="outlined" class="contact card">
    <div class="title">
      <h2>İletişim</h2>
    </div>
    <div class="row">
      <div class="col-sm-6" *ngFor="let contact of company.contactList">
        <p class="name">{{ contact.name }}</p>
        <p>{{ contact.data }}</p>
      </div>
    </div>
  </mat-card>
</div>
