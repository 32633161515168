<ng-container *ngIf="data && !loading">
  <div class="content">
    <div class="d-flex justify-content-between">
      <h2>Takip Ettiklerim</h2>
      <a
        [routerLink]="['/people-extended']"
        routerLinkActive="router-link-active"
        mat-button
        color="primary"
        >Hepsini gör</a
      >
    </div>
    <div class="joined">
      <slider>
        <slider-item *ngFor="let person of data.following">
          <person-card-big [person]="person"></person-card-big>
        </slider-item>
      </slider>
    </div>
    <info-card
      title="Arkadaşlarının Çevresini Keşfet"
      icon="person_add"
      text="Unilocked’da edindiğin arkadaşlarının kendi takip ettiklerine göz atarak daha fazla öğrenci ve profesyonel keşfedin."
      buttonText="Arkadaşları Keşfet"
    ></info-card>
    <div class="following-area">
      <h2>Önerilen kişiler</h2>
      <div class="followed row">
        <div class="col-md-4 col-sm-6" *ngFor="let person of data.suggestions">
          <person-card-small [person]="person"></person-card-small>
        </div>
      </div>
    </div>
    <div class="following-area">
      <h2>Sizi Takip Edenler</h2>
      <div class="followed row">
        <div class="col-md-4 col-sm-6" *ngFor="let person of data.followingYou">
          <person-card-small [person]="person"></person-card-small>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="loading">
  <div class="container mt-4">
    <div class="mt-4">
      <ngx-skeleton-loader
        [theme]="{ width: '300px', height: '30px' }"
      ></ngx-skeleton-loader>

      <div class="row">
        <div class="col-md-3" *ngFor="let i of [1, 2, 3,4]">
          <ngx-skeleton-loader
            [theme]="{ width: '100%', height: '200px' }"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <ngx-skeleton-loader
        [theme]="{ width: '100%', height: '150px' }"
      ></ngx-skeleton-loader>
    </div>

    <div class="mt-4">
      <ngx-skeleton-loader
        [theme]="{ width: '300px', height: '30px' }"
      ></ngx-skeleton-loader>

      <div class="row">
        <div class="col-md-3" *ngFor="let i of [1, 2, 3,4]">
          <ngx-skeleton-loader
            [theme]="{ width: '100%', height: '200px' }"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</ng-container>
