import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { LoginBoxComponent } from './ui/login-box/login-box.component';
import { GuestNavbarComponent } from './ui/navbar/guest-navbar/guest-navbar.component';
import { UserNavbarComponent } from './ui/navbar/user-navbar/user-navbar.component';
import { NavbarComponent } from './ui/navbar/navbar.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { LogoComponent } from './ui/logo/logo.component';
import { InfoCardComponent } from './ui/info-card/info-card.component';
import { EventCardComponent } from './ui/event-card/event-card.component';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { SliderComponent } from './ui/slider/slider.component';
import { SliderItemComponent } from './ui/slider/slider-item/slider-item.component';
import { AdminNavbarComponent } from './ui/navbar/admin-navbar/admin-navbar.component';
import { DateAgoPipe } from './core/pipes/date-ago.pipe';
import { UserMenuComponent } from './ui/navbar/user-navbar/user-menu/user-menu.component';
import { MenuMoreComponent } from './ui/navbar/user-navbar/menu-more/menu-more.component';
import { CenterLoadingComponent } from './ui/center-loading/center-loading.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DateDayMonthPipe } from './core/pipes/date-day-month.pipe';
import { DateHourPipe } from './core/pipes/date-hour.pipe';
import { DateDayNumberPipe } from './core/pipes/date-day-number.pipe';
import { PaginatorComponent } from './ui/paginator/paginator.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AddEventDialogComponent } from './ui/add-event-dialog/add-event-dialog.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { TextEditorModule } from './text-editor/text-editor.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { TagInputComponent } from './ui/tag-input/tag-input.component';
import { MatChipsModule } from '@angular/material/chips';
import { AddEventApplicationDialogComponent } from './ui/add-event-application-dialog/add-event-application-dialog.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DateDayNamePipe } from './core/pipes/date-day-name.pipe';
import { DateMonthNamePipe } from './core/pipes/date-month-name.pipe';
import { DateDayMonthNumberPipe } from './core/pipes/date-day-month-number.pipe';
import { DateMonthYearNumberPipe } from './core/pipes/date-month-year-number.pipe';
import { ApplyEventDialogComponent } from './ui/apply-event-dialog/apply-event-dialog.component';
import { MatRadioModule } from '@angular/material/radio';
import { ApplyEventComponent } from './ui/apply-event/apply-event.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { DateLocalPipe } from './core/pipes/date-local.pipe';
import { AddProjectDialogComponent } from './ui/add-project-dialog/add-project-dialog.component';
import { ImageFilePipe } from './core/pipes/image-file.pipe';
import { MainMenuComponent } from './ui/main-menu/main-menu.component';
import { MainContentComponent } from './ui/main-content/main-content.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { AddJobPostingComponent } from './ui/add-job-posting/add-job-posting.component';
import { FollowButtonComponent } from './ui/follow-button/follow-button.component';
import { AddProfileDialogComponent } from './ui/add-profile-dialog/add-profile-dialog.component';
import { MatSelectModule } from '@angular/material/select';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LoadingChipComponent } from './ui/loading-chip/loading-chip.component';
import { PickPermissionComponent } from './ui/pick-permission/pick-permission.component';
import { AddRoleDialogComponent } from './ui/add-role-dialog/add-role-dialog.component';
import { LikeSaveCardComponent } from 'src/app/site/discovery-catalog/catalog-cards/like-save-card/like-save-card.component';
import { MainTextCardComponent } from 'src/app/site/discovery-catalog/catalog-cards/main-text-card/main-text-card.component';
import { ImagesModule } from './core/images/images.module';
import { InviteAditionalDetailsComponent } from './ui/invite-aditional-details/invite-aditional-details.component';
import { FeedbackFormComponent } from './ui/feedback-form/feedback-form.component';
import { TextInputComponent } from './ui/text-input/text-input.component';
import { MediaImageComponent } from './ui/media-image/media-image.component';
import { LazyLoadModule } from './core/lazy-load/lazy-load.module';
import { ConfirmDialogComponent } from './ui/confirm-dialog/confirm-dialog.component';
import { FeaturedContentComponent } from './ui/featured-content/featured-content.component';
import { VideoPlayerComponent } from './ui/video-player/video-player.component';
import { InViewportModule } from 'ng-in-viewport';
import { PollDialogComponent } from './ui/poll-dialog/poll-dialog.component';
import { PollComponent } from './ui/poll/poll.component';
import { FaviconPipe } from './core/pipes/favicon.pipe';
import { SummaryComponent } from './ui/summary/summary.component';
import { YoumightknowComponent } from './ui/youmightknow/youmightknow.component';
import { AdminMenuComponent } from 'src/app/admin/components/admin-menu/admin-menu.component';
import { MatButtonLoadingDirective } from './core/loading/mat-button-loading.directive';
import { AppUrlPipe } from './core/pipes/app-url.pipe';
import { ErrorDirective } from './core/directives/error.directive';

@NgModule({
  declarations: [
    SummaryComponent,
    YoumightknowComponent,
    GuestNavbarComponent,
    LoginBoxComponent,
    UserNavbarComponent,
    NavbarComponent,
    LogoComponent,
    InfoCardComponent,
    EventCardComponent,
    SliderComponent,
    SliderItemComponent,
    AdminNavbarComponent,
    DateAgoPipe,
    UserMenuComponent,
    MenuMoreComponent,
    CenterLoadingComponent,
    DateDayMonthPipe,
    DateHourPipe,
    DateDayNumberPipe,
    PaginatorComponent,
    AddEventDialogComponent,
    TagInputComponent,
    AddEventApplicationDialogComponent,
    DateDayNamePipe,
    DateMonthNamePipe,
    DateDayMonthNumberPipe,
    DateMonthYearNumberPipe,
    ApplyEventDialogComponent,
    ApplyEventComponent,
    DateLocalPipe,
    AddProjectDialogComponent,
    ImageFilePipe,
    MainContentComponent,
    MainMenuComponent,
    AddJobPostingComponent,
    FollowButtonComponent,
    AddProfileDialogComponent,
    LoadingChipComponent,
    PickPermissionComponent,
    AddRoleDialogComponent,
    LikeSaveCardComponent,
    MainTextCardComponent,
    InviteAditionalDetailsComponent,
    FeedbackFormComponent,
    TextInputComponent,
    MediaImageComponent,
    ConfirmDialogComponent,
    FeaturedContentComponent,
    VideoPlayerComponent,
    PollDialogComponent,
    PollComponent,
    FaviconPipe,
    AdminMenuComponent,
    MatButtonLoadingDirective,
    AppUrlPipe,
    ErrorDirective,
  ],
  imports: [
    MatCardModule,
    CommonModule,
    LazyLoadModule,
    LayoutModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
    MatTooltipModule,
    MatMenuModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    TextEditorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatBottomSheetModule,
    MatSliderModule,
    MatSidenavModule,
    MatListModule,
    MatSelectModule,
    MatOptionModule,
    NgxSkeletonLoaderModule,
    ImagesModule,
    InViewportModule,
  ],
  exports: [
    NavbarComponent,
    InfoCardComponent,
    EventCardComponent,
    SliderComponent,
    SliderItemComponent,
    DateAgoPipe,
    DateDayMonthPipe,
    DateHourPipe,
    CenterLoadingComponent,
    DateDayNumberPipe,
    DateDayNamePipe,
    DateDayMonthNumberPipe,
    DateMonthNamePipe,
    DateMonthYearNumberPipe,
    PaginatorComponent,
    TagInputComponent,
    ApplyEventComponent,
    DateLocalPipe,
    ImageFilePipe,
    MainMenuComponent,
    MainContentComponent,
    FollowButtonComponent,
    LoadingChipComponent,
    MatBadgeModule,
    LikeSaveCardComponent,
    MainTextCardComponent,
    GuestNavbarComponent,
    FeedbackFormComponent,
    TextInputComponent,
    MediaImageComponent,
    FeaturedContentComponent,
    VideoPlayerComponent,
    PollComponent,
    FaviconPipe,
    SummaryComponent,
    YoumightknowComponent,
    AdminMenuComponent,
    MatButtonLoadingDirective,
    AppUrlPipe,
    ErrorDirective
  ],
})
export class AppCommonModule {}
