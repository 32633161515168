<header class="topNavMenu">
  <div class="leftSide">
    <img
      src="assets/images/unilockedlogopgn-08.png"
      class="navbarLogo"
      alt="UniLocked"
    />
    <ul class="navMenu">
      <li
        (click)="changeIcon('menu1')"
        class="menu-item"
        mat-button
        [matMenuTriggerFor]="discoverUniLock"
      >
        Unilocked'u Keşfedin
        <mat-menu #discoverUniLock="matMenu">
          <button mat-menu-item>
            <mat-icon>dialpad</mat-icon>
            <span>Redial</span>
          </button>
          <button mat-menu-item disabled>
            <mat-icon>voicemail</mat-icon>
            <span>Check voice mail</span>
          </button>
          <button mat-menu-item>
            <mat-icon>notifications_off</mat-icon>
            <span>Disable alerts</span>
          </button>
        </mat-menu>
        <mat-icon>{{ menuIcon1 }}</mat-icon>
      </li>
      <li
        (click)="changeIcon('menu2')"
        mat-button
        [matMenuTriggerFor]="forProfessionals"
        class="menu-item"
      >
        Profesyoneller için
        <mat-menu #forProfessionals="matMenu">
          <button mat-menu-item>
            <mat-icon>dialpad</mat-icon>
            <span>Redial</span>
          </button>
          <button mat-menu-item disabled>
            <mat-icon>voicemail</mat-icon>
            <span>Check voice mail</span>
          </button>
          <button mat-menu-item>
            <mat-icon>notifications_off</mat-icon>
            <span>Disable alerts</span>
          </button>
        </mat-menu>
        <mat-icon>{{ menuIcon2 }}</mat-icon>
      </li>
      <li class="menu-item">UniLocked Blog</li>
      <li class="menu-item">Hakkımızda</li>
      <li class="menu-item special">Premium</li>
    </ul>
  </div>
  <!-- Login Button -->
  <div class="rightSide">
    <button
      [matMenuTriggerFor]="mobileMenu"
      type="button"
      class="textButton mobileMenuButton"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #mobileMenu="matMenu">
      <ul class="mobileSideNav">
        <li class="menu-item" mat-button>Unilocked'u Keşfedin</li>
        <li mat-button class="menu-item">Profesyoneller için</li>
        <li class="menu-item">UniLocked Blog</li>
        <li class="menu-item">Hakkımızda</li>
        <li class="menu-item special">Premium</li>
      </ul>
    </mat-menu>

    <a [routerLink]="['/login']" mat-flat-button type="button" class="textButton">Giriş Yapın</a>
    <a [routerLink]="['/register']" mat-flat-button type="button" color="primary" class="primaryButton">Aramıza Katılın</a>
  </div>
</header>
