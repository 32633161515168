<ng-container *ngIf="project$ | async as project; else loading">
  <div style="margin: 0; width: 100%">
    <project-profile-header
      (activeStep)="getActiveTap($event)"
      [project]="project"
    ></project-profile-header>
  </div>
  <div class="row main">
    <!-- Header Start -->

    <!-- Header End -->
    <!-- Content Side Start -->
    <div class="col-md-9 col-12 mt-4">
      <div [ngSwitch]="activeStep" class="row">
        <div *ngSwitchCase="0" class="col-12 d-flex flex-column gap-4">
          <project-profile-about [project]="project"></project-profile-about>
          <project-profile-participants
            title="Katılımcılar"
            [project]="project"
          ></project-profile-participants>
          <project-profile-contact
            [project]="project"
          ></project-profile-contact>
        </div>
        <div *ngSwitchCase="1">
          <project-profile-timeline
            [project]="project"
          ></project-profile-timeline>
        </div>
        <div *ngSwitchCase="2">
          <project-profile-participants
            [project]="project"
          ></project-profile-participants>
        </div>
        <div *ngSwitchCase="3">
          <project-profile-participants
            title="Düzenleyiciler ve Sponsorlar"
            [project]="project"
          ></project-profile-participants>
        </div>
        <div *ngSwitchCase="4">
          <project-missions [projectId]="projectId"></project-missions>
        </div>
      </div>
    </div>
    <!-- Content Side End -->

    <!-- Right Side Start -->
    <div
      class="col-md-3 col-12 mt-4"
      *ngIf="commonPoints$ | async as commonPoints"
    >
      <div class="row" style="margin-top: 16px;">
        <div class="col-12">
          <div class="card">
            <div class="d-flex flex-column">
              <h3>Ortak Noktalar</h3>
              <span class="d-flex align-items-center">
                <mat-icon>visibility</mat-icon>
                <span style="margin-left: 10px">Size Özel</span></span
              >
            </div>
            <div
              class="d-flex flex-column mt-3"
              *ngFor="let point of commonPoints"
            >
              <div
                class="d-flex align-items-center justify-content-center gap-3"
              >
                <media-image
                  width="40px"
                  height="40px"
                  [src]="point.avatar"
                  appereance="circle"
                ></media-image>
                <span style="margin-left: 10px" class="d-flex flex-column">
                  <span>{{ point.text }}</span>
                </span>
              </div>
              <button
                *ngIf="false"
                mat-flat-button
                color="accent"
                class="button-other"
              >
                <mat-icon>chat</mat-icon>Mesajlaşma
              </button>
            </div>
            <div *ngIf="!commonPoints.length">
              <div
                class="d-flex align-items-center justify-content-center gap-3"
              >
                <span
                  >Ortak Nokta Bulunamadı. Daha fazla insan takip etmeyi deneyin
                  ve insanlarla ortak noktanızı keşfedin!</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-4">
          <ng-container *ngIf="youMightKnow$ | async as youMightKnow">
            <mat-card appearance="outlined" class="card">
              <div class="row">
                <h2>
                  {{
                    auth.loggedIn
                      ? "Tanıyor olabilirsin"
                      : "Unilocked Topluluğunun Favorileri"
                  }}
                </h2>
                <div
                  style="cursor: pointer"
                  *ngFor="let user of youMightKnow.items"
                >
                  <div class="d-flex">
                    <img
                      mat-card-avatar
                      class="profile-image"
                      [src]="user.avatar"
                      alt=""
                    />
                    <div style="margin-left: 10px; width: 100%">
                      <div class="top">
                        <h4 class="name" [routerLink]="['/user-profile', user.name]">{{ user.display_name }}</h4>
                      </div>
                      <p class="info">{{ user.headnote }}</p>
                    </div>
                  </div>
                  <div class="action">
                    <button
                      mat-flat-button
                      class="follow"
                      [class.button-primary]="!user.currentUserFollowed"
                      [class.button-other]="user.currentUserFollowed"
                      (click)="toggle($event, user)"
                    >
                      {{
                        user.currentUserFollowed ? "Takibi Bırak" : "Takip Et"
                      }}
                      <mat-icon>
                        {{
                          user.currentUserFollowed
                            ? "person_remove"
                            : "person_add"
                        }}
                      </mat-icon>
                    </button>
                  </div>
                </div>
                <div class="see-more">
                  <a
                    *ngIf="auth.loggedIn"
                    routerLinkActive="router-link-active"
                    mat-button
                    [routerLink]="['/people']"
                  >
                    Daha fazlasını gör <mat-icon>chevron_right</mat-icon>
                  </a>
                </div>
              </div>
            </mat-card>
          </ng-container>
        </div>
      </div>
    </div>
    <!-- Right Side End -->
  </div>
</ng-container>

<ng-template #loading>
  <div class="loading">
    <center-loading></center-loading>
  </div>
</ng-template>
