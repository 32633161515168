<mat-card class="event-card hover-effect-card" [ngClass]="getStatusColor()" [routerLink]="['/event',item._id]">
  <div class="top">
    <div class="title d-flex justify-content-between align-items-center">
      <span class="time d-flex align-items-center">
        <mat-icon> {{ getStatusIcon() }} </mat-icon>
        {{ item.startDate | dateHour }}
      </span>
     
    </div>
    <div class="subtitle">
      {{ getStatusText() }} • {{ getVisibilityText() }}
    </div>
  </div>
  <div class="content">
    <div class="text">
      <span [innerHTML]="item.name"></span>
    </div>
    <div class="actions d-flex align-items-center justify-content-between">
      <mat-icon> query_stats </mat-icon>
      <mat-icon> edit </mat-icon>
      <mat-icon> message </mat-icon>
      <mat-icon> share </mat-icon>
    </div>
  </div>
</mat-card>
