<mat-card appearance="outlined">
  <div class="top">
    <div class="profile-container">
      <div class="image">
        <img [src]="authService.selectedMember.profile.avatar" alt="Avatar" />
      </div>
      <div class="name-container" *ngIf="authService.loggedIn && authService.selectedMember.profileId != authService.user._id">
        <p class="name">{{ authService.selectedMember.profile.name }}</p>
        <p class="sub-name">{{ authService.user.display_name }} olarak giriş yapıldı. <a class="hover-effect" style="color: white; cursor: pointer;" (click)="selectProfile()">Değiştirmek için tıklayın.</a> </p>
      </div>
      <div class="name-container" *ngIf="authService.loggedIn && authService.selectedMember.profileId == authService.user._id">
        <p class="name">{{ authService.user.display_name }}</p>
        <p class="sub-name">{{ authService.user.headnote }}</p>
      </div>
    </div>
  </div>
  <div class="bottom">
    <div class="list">
      <div class="list-item" [routerLink]="authService.selectedMember?.profile | appUrl" (click)="userMenuRef.close()">
        <mat-icon>person</mat-icon>
        <span>Profil</span>
      </div>
      <div class="list-item" *ngIf="authService.loggedIn && authService.selectedMember.profileId != authService.user._id" [routerLink]="['/user-profile', authService.user?.name]" (click)="userMenuRef.close()">
        <mat-icon>account_circle</mat-icon>
        <span>Kullanıcı Profili</span>
      </div>
      <div class="list-item" routerLink="/settings" (click)="userMenuRef.close()">
        <mat-icon>settings</mat-icon>
        <span>Seçenekler</span>
      </div>
      <div class="list-item" routerLink="/admin" (click)="userMenuRef.close()">
        <mat-icon>terminal</mat-icon>
        <span>Profosyonel Pano</span>
      </div>
      <div class="list-item" routerLink="/calendar/week" (click)="userMenuRef.close()">
        <mat-icon>calendar_month</mat-icon>
        <span>Takvim</span>
      </div>
      <div class="list-item" (click)="toggleDarkMode()">
        <mat-icon>{{darkModeIcon}}</mat-icon>
        <span>{{darkModeText}}</span>
      </div>
      <div class="list-item" (click)="selectProfile()">
        <mat-icon>group</mat-icon>
        <span>Profil Seç</span>
      </div>
      <div class="list-item split" (click)="logout()">
        <mat-icon>logout</mat-icon>
        <span>Çıkış yap</span>
      </div>
    </div>
  </div>
</mat-card>
