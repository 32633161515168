<main-content *ngIf="company">
  <div class="company-host container">
    <mat-card appearance="outlined" class="company-top">
      <div class="row">
        <div class="col images" [style]="getStyle(company)">
          <div class="logo">
            <media-image
              appereance="rounded"
              width="100px"
              height="100px"
              [src]="company.avatar"
              alt=""
            ></media-image>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col company-info">
          <div class="description">
            <span class="name">
              <h2>{{ company.name }}</h2>
              <mat-icon> verified </mat-icon>
            </span>
            <span class="text">
              {{ company.headnote }}
            </span>
            <span class="followers"> {{ company.followerCount }} Takipçi </span>
          </div>
          <div class="buttons">
            <button
              *ngIf="isMe"
              mat-stroked-button
              color="primary"
              [routerLink]="['/admin/profile']"
            >
              Profili Düzenle
            </button>
            <follow-button
              *ngIf="!isMe"
              [profile]="company"
              type="University"
            ></follow-button>
            <button
              [matMenuTriggerFor]="menu"
              mat-flat-button
              style="background-color: #c9cbfb; color: #4b53f2"
            >
              Daha Fazla
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="copyLink()">
                <mat-icon>share</mat-icon>
                Paylaş
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </mat-card>
    <div class="row">
      <div class="col-lg-8">
        <mat-tab-group #tabGroup class="tab-group">
          <mat-tab label="Ana Sayfa">
            <mat-card appearance="outlined" class="about card">
              <div class="title">
                <h2>Hakkında</h2>
              </div>
              <p [innerHTML]="company.about"></p>

              <div class="row">
                <div
                  class="col-sm-6"
                  *ngFor="let contact of company.contactList"
                >
                  <p class="name">{{ contact.name }}</p>
                  <p>{{ contact.data }}</p>
                </div>
              </div>
            </mat-card>

            <mat-card appearance="outlined" class="posts card">
              <div class="title">
                <h2>Gönderiler</h2>
                <div>
                  <div>
                    <button mat-button color="primary">Hepsini Gör</button>
                  </div>
                </div>
              </div>
              <slider>
                <slider-item *ngFor="let post of posts">
                  <featured-content
                    [item]="post"
                    [showButton]="false"
                  ></featured-content>
                </slider-item>
              </slider>
            </mat-card>

            <mat-card appearance="outlined" class="featured-content card">
              <div class="title">
                <h2>Öne Çıkan İçerikler</h2>
                <div>
                  <div>
                    <button mat-button color="primary">Hepsini Gör</button>
                  </div>
                </div>
              </div>
              <slider>
                <slider-item *ngFor="let post of company.featuredContent">
                  <featured-content
                    [item]="post.content"
                    [showButton]="false"
                  ></featured-content>
                </slider-item>
              </slider>
            </mat-card>

            <mat-card
              appearance="outlined"
              class="jobs card"
              *ngIf="communities.length"
            >
              <div class="title">
                <h2>Okul Toplulukları</h2>
                <div></div>
              </div>
              <div class="jobs-area">
                <div class="row">
                  <div
                    class="col-6"
                    *ngFor="let community of communities"
                    [routerLink]="['/community', community._id]"
                  >
                    <community-card-small
                      [community]="community"
                    ></community-card-small>
                  </div>
                </div>
              </div>
            </mat-card>

            <mat-card
              appearance="outlined"
              class="jobs card"
              *ngIf="projects?.length"
            >
              <div class="title">
                <h2>Okuldan Projeler</h2>
                <div></div>
              </div>
              <div class="jobs-area">
                <div
                  class="projects-container"
                  *ngIf="getActiveProjects().length"
                >
                  <h3>Devam Edenler</h3>
                  <slider>
                    <slider-item *ngFor="let project of getActiveProjects()">
                      <project-card [project]="project"></project-card>
                    </slider-item>
                  </slider>
                </div>
                <div
                  class="projects-container"
                  *ngIf="getPastProjects().length"
                >
                  <h3>Sona Erenler</h3>
                  <slider>
                    <slider-item *ngFor="let project of getPastProjects()">
                      <project-card [project]="project"></project-card>
                    </slider-item>
                  </slider>
                </div>
              </div>
            </mat-card>

            <mat-card appearance="outlined" class="company-life card">
              <div class="row">
                <div class="col-sm-6">
                  <img [src]="company.backdropFile | imageFile" alt="" />
                </div>
                <div class="col-sm-6">
                  <h2 style="text-align: center">
                    {{ company.name }} Kurumunda Okul Yaşamı
                  </h2>
                  <button
                    mat-flat-button
                    style="background-color: #c9cbfb; color: #4b53f2"
                    (click)="tabGroup.selectedIndex = 1"
                  >
                    Okul Yaşamı
                  </button>
                </div>
              </div>
            </mat-card>

            <mat-card
              appearance="outlined"
              class="jobs card"
              *ngIf="events?.length"
            >
              <div class="title">
                <div>
                  <h2>Yaklaşan Etkinlikler</h2>
                  <p class="subtitle">
                    Listeye okul , okul toplulukları ve okul içinde bulunan
                    kurumlar tarafından düzenlenen etkinlikler dahil edilmiştir.
                  </p>
                </div>
                <div></div>
              </div>
              <div class="jobs-area">
                <div class="row">
                  <div
                    class="col-6"
                    *ngFor="let event of events"
                    [routerLink]="['/event', event._id]"
                  >
                    <event-card [event]="event"></event-card>
                  </div>
                </div>
              </div>
            </mat-card>

            <mat-card
              appearance="outlined"
              class="jobs card"
              *ngIf="jobPostings.length"
            >
              <div class="title">
                <h2>Yeni İş İlanları</h2>
                <div>
                  <div>
                    <button mat-button color="primary">Hepsini Gör</button>
                  </div>
                </div>
              </div>
              <div class="jobs-area">
                <div class="row">
                  <div
                    class="col-6 hover-effect"
                    *ngFor="let job of jobPostings"
                    [routerLink]="['/job-posting', job._id]"
                  >
                    <div class="logo">
                      <media-image
                        appereance="circle"
                        width="48px"
                        height="48px"
                        [src]="job.creator.avatar"
                      ></media-image>
                    </div>
                    <div class="text">
                      <h3>{{ job.title }}</h3>
                      <p>{{ job.locations.join(", ") }}</p>
                      <p>{{ job.viewStartDate | dateAgo }} Yayınlandı</p>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>

            <mat-card appearance="outlined" class="employees card">
              <div class="title">
                <h2>Öğrenciler</h2>
              </div>
              <div class="subtitle">
                <p></p>
              </div>
              <div class="employees-area">
                <div class="row justify-content-between">
                  <div
                    class="col-6 hover-effect"
                    *ngFor="let member of members"
                    [routerLink]="['/user-profile', member.member.name]"
                  >
                    <div class="logo">
                      <media-image
                        appereance="circle"
                        width="48px"
                        height="48px"
                        [src]="member.member.avatar"
                      ></media-image>
                    </div>
                    <div class="text">
                      <h3>{{ member.member.display_name }}</h3>
                      <p>{{ member.member.headnote }}</p>
                      <p></p>
                      <follow-button
                        [profile]="member.member"
                        type="User"
                      ></follow-button>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>

            <mat-card appearance="outlined" class="contact card">
              <div class="title">
                <h2>İletişim</h2>
              </div>
              <div class="row">
                <div
                  class="col-sm-6"
                  *ngFor="let contact of company.contactList"
                >
                  <p class="name">{{ contact.name }}</p>
                  <p>{{ contact.data }}</p>
                </div>
              </div>
            </mat-card>
          </mat-tab>
          <mat-tab label="Okul Yaşamı">
            <mat-card
              style="border-radius: 7px; padding: 16px; margin-top: 16px"
            >
              <h2>{{ company.name }} Kurumunda Okul Yaşamı</h2>
              <p class="live-in" [innerHTML]="company.life"></p>
            </mat-card>
          </mat-tab>
        </mat-tab-group>
      </div>
      <div class="col-lg-4">
        <div class="mt-2 mb-5">
          <summary></summary>
        </div>
        <div class="mb-5">
          <youmightknow></youmightknow>
        </div>
      </div>
    </div>
  </div>
</main-content>
