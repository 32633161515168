<main-content>
  <ng-container *ngIf="user">
    <user-profile-header
      [user]="user"
      [isMe]="currentUser.user?._id === user._id"
    ></user-profile-header>

    <div class="stepper-container">
      <div class="customStepper flex-wrap">
        <span
          (click)="tabs.selectedIndex = 0"
          [class.activeStep]="selectedTab == 0"
          class="step"
        >
          Profil</span
        >
        <span
          (click)="tabs.selectedIndex = 1"
          [class.activeStep]="selectedTab == 1"
          class="step"
        >
          Gönderiler</span
        >
      </div>
    </div>

    <div class="row" style="width: 100%">
      <div class="col-lg-8">
        <mat-tab-group
          class="tabs"
          #tabs
          (selectedTabChange)="selectedTab = $event.index"
        >
          <mat-tab label="Profil">
            <user-about
              [user]="user"
              [isMe]="currentUser.user?._id === user._id"
            ></user-about>
            <!-- Öne Çıkarılanlar -->
            <ng-container
              *ngIf="
                (user.featuredContent && user.featuredContent?.length > 0) ||
                isMe
              "
            >
              <user-featured
                [content]="user.featuredContent"
                [link]="['/user-highlights']"
                [isMe]="currentUser.user?._id === user._id"
              ></user-featured>
            </ng-container>

            <!-- Deneyim -->
            <user-extrasection
              *ngIf="experiences?.length > 0 || isMe"
              [extraSections]="experiences"
              [isMe]="currentUser.user?._id === user._id"
              [type]="'experience'"
            ></user-extrasection>
            <!-- Eğitim -->
            <ng-container *ngIf="user.userType != 'Professional'">
              <user-extrasection
                *ngIf="education?.length > 0 || isMe"
                [extraSections]="education"
                [isMe]="currentUser.user?._id === user._id"
                [type]="'education'"
              ></user-extrasection>
            </ng-container>
            <!-- Projeler -->
            <ng-container *ngIf="user.projectsParticipated?.length > 0">
              <user-projects
                [projects]="user?.projectsParticipated"
                [isMe]="currentUser.user?._id === user._id"
              ></user-projects>
            </ng-container>
            <!--Sertifikalar-->
            <user-extrasection
              *ngIf="certificates?.length > 0 || isMe"
              [extraSections]="certificates"
              [isMe]="currentUser.user?._id === user._id"
              [type]="'certificate'"
            ></user-extrasection>
            <user-communities
              *ngIf="communities?.length > 0 || isMe"
              [communities]="user.memberTo"
              [isMe]="currentUser.user?._id === user._id"
            ></user-communities>
            <user-skills
              *ngIf="talents?.length > 0 || isMe"
              [talents]="talents"
              [isMe]="currentUser.user?._id === user._id"
            ></user-skills>
            <user-following
              [followings]="user.following"
              [isMe]="currentUser.user?._id === user._id"
            ></user-following>
          </mat-tab>
          <mat-tab label="Gönderiler">
            <!-- Gönderiler -->
            <ng-container *ngIf="user.posts && user.posts?.length > 0">
              <user-posts
                [posts]="user?.posts"
                [link]="['/user-posts']"
                [isMe]="currentUser.user?._id === user._id"
              ></user-posts>
            </ng-container>
          </mat-tab>
        </mat-tab-group>
      </div>
      <div class="col-lg-4">
        <div class="mt-2 mb-5">
          <summary></summary>
        </div>
        <div class="mb-5">
          <youmightknow></youmightknow>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="loading$ | async">
    <div class="container">
      <ngx-skeleton-loader
        [theme]="{ height: '200px', width: '100%', marginTop: '20px' }"
      ></ngx-skeleton-loader>
      <div class="row">
        <div class="col-lg-8">
          <ngx-skeleton-loader
            [count]="5"
            [theme]="{ height: '200px', width: '100%', marginTop: '20px' }"
          ></ngx-skeleton-loader>
        </div>
        <div class="col-lg-4">
          <ngx-skeleton-loader
            [count]="5"
            [theme]="{ height: '200px', width: '100%', marginTop: '20px' }"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </ng-container>
</main-content>
