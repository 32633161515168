<mat-toolbar class="mat-toolbar" color="primary">
  <div class="logo">
    <a class="logo-container" [routerLink]="['/home']"
      ><img [src]="logo | async" class="logo" alt="UniLocked"
    /></a>
  </div>

  <div class="buttonService">
    <p>{{ buttonService.getButtonName() || "" }}</p>
  </div>

  <form [formGroup]="searchForm" (ngSubmit)="search()">
    <div class="container">
      <div class="navbar-search">
        <mat-icon class="navbar-icon">search</mat-icon>
        <input
          formControlName="query"
          placeholder="Aklınızda neler var?"
          class="navbar-input"
          type="text"
        />
      </div>

      <button
        type="button"
        class="createPost"
        routerLink="/home"
        *ngIf="auth.loggedIn"
      >
        <mat-icon>add</mat-icon>
        Gönderi Oluştur
      </button>
    </div>
  </form>

  <div class="navbar-out">
    <button
      *ngIf="auth.loggedIn"
      mat-icon-button
      class="icons"
      aria-label="Example icon-button with heart icon"
      routerLink="/messages"
    >
      <mat-icon class="icons" matBadgeColor="primary">messages</mat-icon>
    </button>
    <button
      mat-icon-button
      *ngIf="auth.loggedIn"
      class="notifications-icon"
      aria-label="Example icon-button with share icon"
      routerLink="/notifications"
    >
      <mat-icon
        [matBadge]="auth.unreadNotifications + ''"
        matBadgeColor="warn"
        class="icons"
        [matBadgeDisabled]="!auth.unreadNotifications"
        [matBadgeHidden]="!auth.unreadNotifications"
        >notifications</mat-icon
      >
    </button>
    <button
      #more
      mat-icon-button
      aria-label="Example icon-button with share icon"
      (click)="openMoreMenu()"
    >
      <mat-icon>apps</mat-icon>
    </button>

    <a
      *ngIf="!auth.loggedIn"
      [routerLink]="['/login']"
      mat-flat-button
      class="button-other"
      >Giriş Yapın</a
    >
    <a
      *ngIf="!auth.loggedIn"
      [routerLink]="['/register']"
      mat-flat-button
      class="button-primary"
      >Aramıza Katılın</a
    >

    <!-- Profil image -->
    <div class="user-area" *ngIf="auth.loggedIn">
      <div
        style="position: relative; display: block; width: 40px; height: 40px"
      >
        <img
          #avatar
          style="position: absolute"
          class="profile-image"
          (click)="openUserMenu()"
          [src]="auth.user.avatar"
          alt=""
        />
        <img
          *ngIf="auth.selectedMember.profileId != auth.user._id"
          style="position: absolute; margin-left: 6px; margin-top: 6px"
          class="profile-image hover-effect"
          (click)="openUserMenu()"
          [src]="auth.selectedMember.profile.avatar"
          alt=""
        />
      </div>

      <mat-menu #menu="matMenu">
        <button
          [disabled]="auth.loading$ | async"
          (click)="logout()"
          mat-menu-item
        >
          <mat-icon>logout</mat-icon>
          <span>Çıkış Yap</span>
        </button>

        <button
          routerLink="/settings"
          [disabled]="auth.loading$ | async"
          mat-menu-item
        >
          <mat-icon>settings</mat-icon>
          <span>Seçenekler</span>
        </button>

        <button (click)="toggleDarkMode()" mat-menu-item>
          <mat-icon>image</mat-icon>
          <span>{{ theme.darkMode ? "Açık Tema" : "Koyu Tema" }}</span>
        </button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>
