<!-- Home Page -->
<!-- Wrapper -->
<div class="guest-homepage">
  <!-- Navbar -->
  <guest-navbar></guest-navbar>
  <!-- Banner Start -->
  <section id="banner" class="banner">
    <div class="headerContainer">
      <span class="header">Daha iyi bir gelecek 🌱 inşa edenler için. </span>
      <span class="description"
        >Kendini geliştirmenin her zaman yeni yollarını arayan öğrenciler ve
        geleceğin yıldızlarını keşfetmek isteyen profesyoneller artık tek bir
        platformda.</span
      >
    </div>
    <div class="inputWrapper">
      <div class="inputContainer">
        <div class="inputWithIcon">
          <mat-icon style="color: gray">mail</mat-icon>

          <input
            type="text"
            [(ngModel)]="mail"
            placeholder="E-posta adresinizi girin"
            name="mail"
            id="mailInput"
          />
        </div>
        <a
          (click)="openBetaDialog(mail)"
          type="submit"
          class="mailButton"
        >
          Bugün Aramıza Katılın
          <mat-icon>arrow_forward</mat-icon>
        </a>
      </div>
      <div class="altOptions">
        <span>veya</span>
        <a
          href="/api/auth/social/google/login"
          mat-button
          class="iconButton"
          type="button"
        >
          <div class="d-flex align-items-center" style="gap: 0.3rem">
            <img src="assets/svg/googleIcon.svg" width="20" />
            <span> Google ile Kaydolun</span>
          </div>
        </a>
        <button class="iconButton" type="button">
          <mat-icon>apple</mat-icon> Apple ile Kaydolun
        </button>
      </div>
    </div>
    <div class="ImageBanner">
      <div class="shapeWrapper">
        <div class="shape1">
          <img src="assets/img/alekted.png" class="shapeImg1" />
          <img src="assets/svg/LineCurveLeft.svg" class="line1" />
        </div>
        <div class="shape2">
          <img src="assets/img/home_person.jpeg" class="shapeImg2" />
          <img src="assets/svg/LineCurveRight.svg" class="line2" />
        </div>
        <div class="shape3">
          <img src="assets/img/adobeLogo.png" class="shapeImg3" />
          <img src="assets/svg/LineCurveSmallRight.svg" class="line3" />
        </div>
      </div>
      <img src="assets/img/earth.png" class="bannerImg" />
    </div>
  </section>
  <!-- Banner End -->
  <!-- Reference Message Start -->
  <section class="refMessage">
    <div class="messageWrapper">
      <h3>Unilocked Erken Erişim Başladı!</h3>
      <span
        >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
        sit amet vehicula libero. Aenean in porta massa. Sed et est sollicitudin
        nulla molestie ornare. Cras eleifend eget risus sed tempus.

        <br />
        <br />
        Donec non nibh aliquet, feugiat eros nec, mollis mauris. Ut ac velit sed
        risus venenatis efficitur eu facilisis velit. Mauris rhoncus pharetra
        justo, vel sodales ante rutrum sed. Donec vestibulum, quam in tempor
        venenatis, magna elit maximus nisl, in porttitor justo nisl sodales
        lorem.

        <br />
        <br />
        Curabitur a volutpat ligula. Cras consequat risus eget enim congue, eu
        pretium ligula tincidunt. Sed eros nulla, hendrerit ut urna vel, semper
        tincidunt turpis. Vivamus commodo blandit dolor eu facilisis. Cras sit
        amet risus urna. Ut vestibulum tempor purus, ut accumsan mauris
        venenatis nec.
      </span>
      <p>- Unilocked Ekibi 💙</p>
    </div>
    <div class="refMessageButtonWrapper">
      <button type="button" class="primaryButton">
        Bugün Erken Erişime Katılın <mat-icon>arrow_forward</mat-icon>
      </button>
      <span>veya</span>
      <a  href="/api/auth/social/google/login" class="secondaryButton" type="button">
        <img src="assets/img/google.png" width="20" /> Google ile Kaydolun
      </a>
      <button class="secondaryButton" type="button">
        <mat-icon>apple</mat-icon> Apple ile Kaydolun
      </button>
    </div>
  </section>
  <!-- Reference Message End -->
  <!-- About Us Sction Start -->
  <section class="aboutUsWrapper">
    <div class="headerWrapper">
      <h1 class="header">Unilocked <b>Topluluğuna</b> 💙 Hoşgeldiniz</h1>
      <div class="statisticWrapper">
        <span><b>150.000+</b> Öğrenci ve Profesyonel </span>
        <span><b>1.000+</b> Topluluk </span>
        <span><b>93</b> Teknopark </span>
        <span><b>500+</b> Şirket </span>
      </div>
    </div>
    <span
      >Öğrencilerin, öğretim hayatlarını dolu dolu geçirip potansiyellerini
      keşfetmesi,
      <br />
      <br />
      Profesyonellerin, geleceğin yıldızlarını yetiştirmesi için:</span
    >
    <div class="descriptionWrapper">
      <div class="description">
        <span class="overlay">Donanım ve Ağ</span>
        <span
          >sektörüne ilgi duyan öğrencileri ve sektörün profesyonellerini bir
          araya getiriyoruz</span
        >
      </div>
      <div class="description">
        <span class="overlay">İletişim</span>
        sektörü hakkında aklına takılan soruları olan öğrencileri sektöründe
        lider kişiler ile buluşturuyoruz
      </div>
      <div class="description">
        <span class="overlay">Perakende</span>
        sektöründe çalışan profesyonellerin öğrencilere kolayca ulaşabilmesi
        için araçlar sunuyoruz
      </div>
      <div class="description">
        <span class="overlay">Finans</span>
        sektörü üzerine eğitim alan öğrencilerin istedikleri stajı kolayca
        bulmasını sağlıyoruz
      </div>
      <div class="description">
        <span class="overlay">Gayrimenkul</span>
        sektörü hakkında öğrencileri bilgilendirmek isteyen şirketler için
        platform sağlıyoruz
      </div>
    </div>
    <span class="bottomDesc"
      >ve çok daha fazlasını yapıyoruz. Geleceğin yıldızları 🌟 için.
    </span>
  </section>
  <!-- About Us Sction End -->
  <!-- Discover Us Start -->
  <section class="discoverUsSection">
    <h2 class="title">Unilocked’u Keşfedin</h2>
    <div class="cardWrapper">
      <div class="card">
        <div class="leftSide">
          <div class="leftSideHeaderWrapper">
            <mat-icon>explore</mat-icon>
            <div class="header">
              <h3>Keşif</h3>
              <span
                >Sizin gibi her zaman bir adım ileri gitmek için can atanlar ile
                tanışın, ilham verene gönderilere göz atın.</span
              >
            </div>
          </div>
          <a href="/" class="textButton"
            >Keşif Sayfasını Ziyaret Edin <mat-icon>arrow_forward</mat-icon></a
          >
        </div>
        <img src="assets/img/post-1.png" class="cardImage" />
      </div>
      <div class="column">
        <div class="card">
          <div class="leftSide">
            <div class="leftSideHeaderWrapper">
              <mat-icon>layers</mat-icon>
              <div class="header">
                <h3>Keşif Kataloğu</h3>
                <span
                  >Sadece sizin için hazırlanmış Keşif Kataloğu ile yeni
                  kişiler, projeler, ilanlar, etkinlikler ve daha fazlasını
                  bulun.</span
                >
              </div>
            </div>
            <a href="/" class="textButton"
              >Sizin Özel Katalog İçin Aramıza Katılın
              <mat-icon>arrow_forward</mat-icon></a
            >
          </div>
          <img src="assets/img/post-1.png" class="cardImage" />
        </div>
        <div class="card">
          <div class="leftSide">
            <div class="leftSideHeaderWrapper">
              <mat-icon>lightbulb</mat-icon>
              <div class="header">
                <h3>Projeler</h3>
                <span
                  >Geleceği inşa etmek isteyenlere katılın, birbirlerine her gün
                  yeni şeyler katan ekiplerin parçası olun.</span
                >
              </div>
            </div>
            <a href="/" class="textButton"
              >Projeleri Keşfedin <mat-icon>arrow_forward</mat-icon></a
            >
          </div>
          <img src="assets/img/post-1.png" class="cardImage" />
        </div>
      </div>
      <div class="column">
        <div class="card">
          <div class="leftSide">
            <div class="leftSideHeaderWrapper">
              <mat-icon>newspaper</mat-icon>
              <div class="header">
                <h3>İlanlar</h3>
                <span
                  >Çok istediğiniz stajınızı veya ilk işinizi bulun, kendi
                  yetenekleriniz ve isteklerinize göre fırsatlar yaratın.</span
                >
              </div>
            </div>
            <a href="/" class="textButton"
              >En Yeni İlanları Keşfedin <mat-icon>arrow_forward</mat-icon></a
            >
          </div>
        </div>
        <div class="card">
          <div class="leftSide">
            <div class="leftSideHeaderWrapper">
              <mat-icon>event</mat-icon>
              <div class="header">
                <h3>Etkinlikler</h3>
                <span
                  >İlham veren insanlarla beraber olun, sektörünün önderleriyle
                  tanışın.</span
                >
              </div>
            </div>
            <a href="/" class="textButton"
              >En Yeni İlanları Keşfedin <mat-icon>arrow_forward</mat-icon></a
            >
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Discover Us End -->
  <!-- All Community Section Start -->
  <section class="communitySection">
    <home-partners class="partners"></home-partners>
    <div class="describeZone">
      <h3>Her Alandan Topluluklar</h3>
      <span
        >Kendini geliştirmek için hep yeni yollar arayanlar için en iyi
        topluluklar Unilocked’da.</span
      >
      <div class="buttonSection">
        <button type="button" class="primaryButton">
          Unilocked Topluluklarına Göz Atın
        </button>
        <button type="button" class="textButton">
          Kendi Topluluğunuzu Oluşturun <mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
    </div>
  </section>
  <!-- All Community Section End -->
  <!-- All Partners -->
  <section class="partnersSection">
    <h2 class="partnerSectionHeader">Unilocked Partnerleri</h2>
    <div class="partnersSlider">
      <h3>En Prestijli Okullar ve En Büyük Şirketler Unilocked’da</h3>
      <div class="slider">
        <div class="slider-item" *ngFor="let item of sliderImgData">
          <img [src]="item.path" width="150" />
          <div class="dot"></div>
        </div>
      </div>
    </div>
    <!-- quotes -->
    <div class="quotes">
      <div class="desc">
        <span
          >“ Proin venenatis ante risus, at luctus felis elementum vitae. Fusce
          accumsan felis ac congue tristique. Suspendisse elit lorem,
          pellentesque non lectus eu, luctus venenatis velit. Nunc nunc nunc,
          molestie eget scelerisque. ”</span
        >
      </div>
      <div class="quotesOwner">
        <div class="quotesOwnerInfo">
          <span>Felya Varol</span>
          <span>İnsan Kaynakları Yöneticisi, Turkcell</span>
        </div>
        <img src="assets/img/turkcell-dark.png" width="160" />
      </div>
      <div class="customRow">
        <div class="col">
          <span
            >Partner İşareti ile kurumunuzun Unilocked platformundaki
            güvenilirliğini sağlayın.</span
          >
          <img src="assets/svg/notifIcon.svg" width="96" height="96" />
        </div>
        <div class="col">
          <span
            >İçeriklerinizin kalitesini arttırın, daha geniş kitlelere
            açılın.</span
          >
          <img src="assets/svg/galleryIcon.svg" width="96" height="96" />
        </div>
        <div class="col">
          <img src="assets/svg/wheelIcon.svg" width="96" height="96" />
          <span
            >Her türlü sorununuzda öncelikli destek hattımıza başvurun.</span
          >
        </div>
        <div class="col">
          <div class="headerWrapper">
            <span>Bunlar ve daha fazla avantaj için</span>
            <h3>Bugün Unilocked Partneri Olun</h3>
          </div>
          <div class="buttonWrapper">
            <button class="primaryButton">Partner Programını Keşfedin</button>
            <button class="textButton">
              Partnerlik Platformu <mat-icon>arrow_forward</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Blog Section start  -->
  <section class="blogSection">
    <div class="blogHeaderWrapper">
      <h1>Blog</h1>
      <span>Unilocked Blog’dan en son favorilerimiz 💖</span>
    </div>
    <div class="boxWrapper">
      <div class="col">
        <div class="box">
          <img src="assets/img/box1.png" width="100%" height="686" />
          <div class="boxBody">
            <div class="top">
              <span class="tag">UNILOCKED</span>
              <img src="assets/svg/BlogIcon.svg" width="56" height="56" />
            </div>

            <div class="content">
              <h2>Keşif Sayfasını Keşfetmek</h2>
              <span
                >Keşif sayfasını en iyi şekilde kullanmaya başlamak için
                ipuçları burada.</span
              >
              <p class="altText">Unilocked tarafından • 2 dakika okuma</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="box">
          <img src="assets/img/box2.png" width="100%" height="195" />
          <div class="boxBody">
            <div class="top">
              <span class="tag">TASARIM</span>
              <img src="assets/svg/paintIcon.svg" width="56" height="56" />
            </div>
            <div class="content">
              <h2>Tasarım Temelli Düşünme</h2>
              <span
                >Design Thinking insan odaklı bir tasarım olduğu için doğal
                olarak ilk aşama empati yapmaktır. Bu aşamada hedefiniz
                kullanıcıyı anlamaya çalışmak olmalıdır. Kullanıcının
                deneyimlerini, iyi ve kötü tecrübelerini ve hedeflerini
                gözlemleyerek kullanıcı hakkında daha kapsamlı bilgi sahibi
                olabilirsiniz.</span
              >
              <p class="altText">
                Hannah Murray tarafından • 2 dakika okuma • Otomatik olarak
                Türkçe diline çevirilmiştir
              </p>
            </div>
          </div>
        </div>
        <div class="box">
          <img src="assets/img/box3.png" width="100%" height="195" />
          <div class="boxBody">
            <div class="top">
              <span class="tag">PROGRAMALAMA</span>
              <img src="assets/svg/codeIcon.svg" width="56" height="56" />
            </div>
            <div class="content">
              <h2>GitHub Temelleri</h2>
              <span
                >Teknasyon ailesinin bir parçası olduktan sonra Samcro ekibi ile
                ilk projemde çalışmaya başladım. Bu süreçte bireysel projede
                çalışmak ile ortak ve büyük bir projede çalışmanın farklarını da
                görme fırsatım oldu. Ayrıca Git’in (versiyon kontrol sistemi)
                önemini ve projenin ilerleme sürecindeki etkilerini de görmüş
                oldum.</span
              >
              <p class="altText">Süleyman İŞLER tarafından • 5 dakika okuma</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Alt section -->
  <section class="altCreateAccountSection">
    <h1>
      Daha iyi bir gelecek için 🌱 Unilocked Topluluğunun bir parçası olun.
    </h1>
    <div class="inputWrapper">
      <div class="inputContainer">
        <div class="inputWithIcon">
          <mat-icon style="color: gray">mail</mat-icon>

          <input
            [(ngModel)]="mail"
            type="text"
            placeholder="E-posta adresinizi girin"
            name="mail"
            id="mailInput"
          />
        </div>
        <a
          mat-button
          (click)="openBetaDialog(mail)"
          type="submit"
          class="mailButton"
        >
          Bugün Aramıza Katılın
          <mat-icon>arrow_forward</mat-icon>
        </a>
      </div>
      <div class="altOptions">
        <span>veya</span>
        <button class="iconButton" type="button">
          <img src="assets/svg/googleIcon.svg" width="20" /> Google ile Kaydolun
        </button>
        <button class="iconButton" type="button">
          <mat-icon>apple</mat-icon> Apple ile Kaydolun
        </button>
      </div>
    </div>
    <span
      >Bugün Aramıza Katıl butonuna tıklayarak, Unilocked
      <u> Kullanıcı Anlaşmasını </u>, <u>Gizlilik Politikasını</u> ve
      <u>Çerez Politikasını</u> kabul etmiş olursunuz.</span
    >
  </section>
  <!-- footer -->
  <footer class="footerSec">
    <div class="topSide">
      <img src="assets/img/logo-dark.png" width="186" />

      <div class="socialMedia">
        <img src="assets/svg/instagramIcon.svg" width="40" />
        <img src="assets/svg/facebookIcon.svg" width="40" />
        <img src="assets/svg/twitterIcon.svg" width="40" />
      </div>
    </div>
    <div class="menuSide">
      <div class="altMenu">
        <h4>GENEL</h4>
        <ul>
          <li>Keşfet</li>
          <li>Kişiler</li>
          <li>İlanlar</li>
          <li>Programlar</li>
        </ul>
      </div>
      <div class="altMenu">
        <h4>HAKKIMIZDA</h4>
        <ul>
          <li>Hakkımızda</li>
          <li>Unilocked Partnerleri</li>
          <li>Kariyer</li>
        </ul>
      </div>
      <div class="altMenu">
        <h4>DESTEK</h4>
        <ul>
          <li>Destek Merkezi</li>
          <li>Destek İsteği Oluşturun</li>
          <li>SSS</li>
        </ul>
      </div>
    </div>
    <div class="copyright">
      <span>Unilocked ©2024 - All Rights Deserved</span>
      <ul>
        <li>Erişebilirlik</li>
        <li>Kullanıcı Anlaşması</li>
        <li>Gizlilik Politikası</li>
        <li>Çerez Politikası</li>
      </ul>
    </div>
  </footer>
</div>
