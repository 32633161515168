import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@common/auth/auth.service';
import { Post } from 'src/app/models/post';
import { PostsService } from '../../posts/posts.service';
import { Toast } from '@common/core/toast/toast/toast.service';
import { BreakpointsService } from '@common/ui/breakpoints.service';

@Component({
  selector: 'post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
})
export class PostComponent implements OnInit {
  @Input('post') post: Post;
  @Input('showCommentButton') showCommentButton = true;
  public showReplies = false;

  constructor(public postService: PostsService, private toast: Toast, public auth:AuthService, public breakPoints:BreakpointsService) {}


  public isMobile$ = this.breakPoints.isMobile$;
  public isSaved: boolean;

  public share() {
    const link = 'https://unilocked.com/post/' + this.post._id;
    navigator.clipboard.writeText(link).then(() => {
      this.toast.open('Bağlantı kopyalandı.');
    });
  }

  public getLink() {
    if (!this.post.postedBy) {
      return ['/home']
    }
    if (this.post.postedByType == "User") {
      return ['/user-profile', this.post.postedBy.name]
    } else if (this.post.postedByType == "Company") {
      return ['/company', this.post.postedBy._id]
    } else if (this.post.postedByType == "Community") {
      return ['/community', this.post.postedBy._id]
    }
    return ['/school', this.post.postedBy._id];
  }

  ngOnInit(): void {
    this.isSaved = this.postService.isSaved(this.post);
  }

  public toggleComments(event) {
    this.showReplies = !this.showReplies;
  }

  public savePost() {
    this.postService.savePost(this.post).subscribe((post) => {
      this.isSaved = true;
    });
  }

  public unsavePost() {
    this.postService.unsavePost(this.post).subscribe((post) => {
      this.isSaved = false;
    });
  }

  public alreadyReacted(reaction) {
    if (this.post.userReactions) {
      return this.post.userReactions.find(
        (userReaction) => userReaction.reaction === reaction
      ) !== undefined;
    }
    return false;
    
  }

  public deletePost() {
    this.postService.delete(this.post._id).subscribe(() => {
      this.toast.open('Post silindi');
    });
  }

  public addReaction(reaction) {
    if (!this.post.reactionsCounts) {
      this.post.reactionsCounts = { like: 0, dislike: 0 };
    }
    if (this.post.userReactions) {
      const alreadyReacted = this.post.userReactions.find(
        (userReaction) => userReaction.reaction === reaction
      ) !== undefined;
      console.log(this.post.userReactions,alreadyReacted,reaction);

      for (const userReaction of this.post.userReactions) {
        this.post.reactionsCounts[userReaction.reaction]--;
      }
      this.post.userReactions = []
      if (!alreadyReacted) {
        this.post.reactionsCounts[reaction]++;
        this.post.userReactions.push({
          reaction,
          user: null,
          post: null,
        });
      }
    }
    for (const userReaction in this.post.reactionsCounts) {
      if (this.post.reactionsCounts[userReaction] < 0) {
        this.post.reactionsCounts[userReaction] = 0;
      }
    }
    this.postService.addReaction(this.post, reaction).subscribe((post) => {
      //this.post.reactionsCounts = post.reactionsCounts;
      
      
      //console.log(this.post.userReactions);
    });
  }
}
