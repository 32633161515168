import { Component, Input, OnInit } from '@angular/core';
import { PostsService } from '../posts.service';
import { Post } from 'src/app/models/post';
import { AuthService } from '@common/auth/auth.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'post-replies',
  templateUrl: './post-replies.component.html',
  styleUrls: ['./post-replies.component.scss'],
})
export class PostRepliesComponent implements OnInit {
  constructor(public posts: PostsService, public auth: AuthService) {}

  @Input({ required: true }) post: Post;
  @Input() depth = 0;

  public replies$ = new BehaviorSubject<Post[]>([]);
  public loading$ = new BehaviorSubject<boolean>(true);
  public hasMore$ = new BehaviorSubject<boolean>(false);
  public currentPage$ = new BehaviorSubject<number>(1);

  ngOnInit(): void {
    this.loading$.next(true);
    this.loadPage(1);
  }

  public loadPage(page: number) {
    this.posts.getReplies(this.post._id, 1).subscribe((replies) => {
      this.replies$.next([...this.replies$.value, ...replies.data.data]);
      this.loading$.next(false);
      this.hasMore$.next(replies.data.current_page < replies.data.last_page);
    });
  }

  public loadMore() {
    this.currentPage$.next(this.currentPage$.value + 1);
    this.loadPage(this.currentPage$.value);
  }

  public addReply(post: Post) {
    this.replies$.next([post, ...this.replies$.value]);
  }
}
