import { EventCalendarNote } from './../../app/models/relations/events/event-calendar-note';
import { Injectable } from '@angular/core';
import { AppHttpClient } from '@common/core/http/http.service';
import { StandartResponse } from '@common/core/http/standart-response';
import { Event } from 'src/app/models/event';
import { Pagination } from 'src/app/models/pagination';
import { EventTicket } from 'src/app/models/relations/events/event-ticket';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

const BASE = 'events';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(public http: AppHttpClient) {}

  public getHome() {
    return this.http.get<StandartResponse<EventsHomeData>>(BASE);
  }

  public create(event: Event) {
    return this.http.post<StandartResponse<Event>>(BASE, {
      event,
    });
  }

  public get(id: string) {
    return this.http.get<StandartResponse<Event>>(`${BASE}/${id}`);
  }

  public update(id: string, payload: any) {
    return this.http.put<StandartResponse<Event>>(`${BASE}/${id}`, payload);
  }

  public getCreator(
    id: string
  ): Observable<StandartResponse<{ creatorAvatar: string }>> {
    return this.http.get<StandartResponse<{ creatorAvatar: string }>>(
      `${BASE}/${id}/creator`
    );
  }

  public getRecommendedEvents() {
    return this.http
      .get<StandartResponse<Event>>(`${BASE}/recommended`)
      .pipe(tap((events) => console.log('Recommended Events:', events)));
  }

  public getPastEvents() {
    return this.http.get<StandartResponse<Pagination<Event>>>(`${BASE}/past-events`);
  }

  public getTickets(id: string, page: number = 1) {
    return this.http.get<
      StandartResponse<{
        pagination: Pagination<EventTicket>;
      }>
    >(`${BASE}/${id}/tickets`, {
      page: page,
    });
  }

  public updateApplication(eventId: string, applicationId: string, data: any) {
    return this.http.put<StandartResponse<EventTicket>>(
      `${BASE}/${eventId}/applications/${applicationId}`,
      data
    );
  }

  public addApplication(id: string, data: any) {
    return this.http.post<StandartResponse<EventTicket>>(
      `${BASE}/${id}/add-application`,
      data
    );
  }

  public getCalendarList(profileId: string) {
    return this.http.get<StandartResponse<{ date: Date; events: Event[] }[]>>(
      `${BASE}/calendar-list/${profileId}`
    );
  }

  public getCalendarMonth(profileId: string, month: Date) {
    return this.http.post<StandartResponse<{ date: Date; events: Event[] }[]>>(
      `${BASE}/calendar-moth/${profileId}`,
      {
        startDate: month.toISOString(),
      }
    );
  }

  public getCalendarWeek(profileId: string, week: Date) {
    return this.http.post<
      StandartResponse<{
        data: { note: EventCalendarNote; date: Date; events: Event[] }[];
        viewAnalytics: {
          currentViews: number;
          olderEventViews: number;
          viewsDifference: number;
          viewsDifferencePercentage: string;
        };
        ticketAnalytics: {
          currentTickets: number;
          olderEventTickets: number;
          ticketsDifference: number;
          ticketsDifferencePercentage: string;
        };
      }>
    >(`${BASE}/calendar-week/${profileId}`, {
      startDate: week,
    });
  }

  public addCalendarNote(profileId: string, data: EventCalendarNote) {
    return this.http.post<StandartResponse<EventCalendarNote>>(
      `${BASE}/add-calendar-note`,
      {
        profileId,
        ...data,
      }
    );
  }

  public removeCalendarNote(_id: string) {
    return this.http.delete<StandartResponse<EventCalendarNote>>(
      `${BASE}/calendar-note/${_id}`
    );
  }

  public getEventAnalytics(eventId: string, selectedProfileId: string) {
    return this.http.post<
      StandartResponse<{
        analytics: {
          view: {
            viewsDifferencePercentage: string;
            viewsDifference: number;
            averageViews: number;
            currentViews: number;
          };
          application: {
            ticketsDifferencePercentage: string;
            ticketsDifference: number;
            averageTickets: number;
            currentTickets: number;
          };
        };
      }>
    >(`${BASE}/${eventId}/analytics`, {
      selectedProfileId,
    });
  }

  buyTicket(eventId: string, applicationId: string) {
    return this.http.post<StandartResponse<EventTicket>>(
      `${BASE}/${eventId}/application/${applicationId}/ticket`,
      {
        applicationId,
      }
    );
  }
}

export interface EventsHomeData {
  upcoming: Event[];
  past: Event[];
  suggestions: Event[];
}
