import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EventCalendarNote } from 'src/app/models/relations/events/event-calendar-note';
import { AddCalendarNoteDialogComponent } from '../add-calendar-note-dialog/add-calendar-note-dialog.component';
import { EventsService } from '@common/services/events.service';

@Component({
  selector: 'view-calendar-note-dialog',
  templateUrl: './view-calendar-note-dialog.component.html',
  styleUrls: ['./view-calendar-note-dialog.component.scss'],
})
export class ViewCalendarNoteDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EventCalendarNote,
    public dialog: MatDialog,
    public ref: MatDialogRef<ViewCalendarNoteDialogComponent>,
    public events: EventsService
  ) {}

  public edit() {
    
    const ref = this.dialog.open(AddCalendarNoteDialogComponent, {
      data: {
        day: this.data.day,
        month: this.data.month,
        year: this.data.year,
        oldNote: this.data,
      },
    });
    ref.afterClosed().subscribe((result) => {
      if (result) {
        this.ref.close(result);
      }
    } );
  }

  public remove() {
    this.events.removeCalendarNote(
     this.data._id
    ).subscribe(() => {
      this.ref.close(true);
    });
  }
}
