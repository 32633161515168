import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@common/auth/auth.service';
import { Observable, map } from 'rxjs';
import { Pagination } from 'src/app/models/pagination';
import { Post } from 'src/app/models/post';
import { FormArray, FormBuilder } from '@angular/forms';
import { UploadService } from '@common/core/upload/upload.service';
import { HomeService } from '@common/services/home.service';
import { FollowersService } from '@common/services/followers.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { FeedbackOpenerServiceService } from '@common/services/feedback-opener-service.service';
import { FeedbackFormComponent } from '@common/ui/feedback-form/feedback-form.component';
import { PollDialogComponent } from '@common/ui/poll-dialog/poll-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ContentEditingService } from 'src/app/admin/content-editing/content-editing.service';
import { Router } from '@angular/router';
import { PostService } from 'src/app/admin/services/post.service';
import { PostsService } from '../../posts/posts.service';

@Component({
  selector: 'user-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.scss'],
})
export class UserHomeComponent implements OnInit {
  public posts$: Observable<Pagination<Post>> = new Observable((observer) => {
    try {
      const data = JSON.parse(localStorage.getItem('posts'));
      observer.next(data);
    } catch (error) {}
  });

  constructor(
    public auth: AuthService,
    private breakPointObserver: BreakpointObserver,
    public homeService: HomeService,
    public fb: FormBuilder,
    private uploadService: UploadService,
    public followerService: FollowersService,
    private bottomSheetService: MatBottomSheet,
    private feedOpenerService: FeedbackOpenerServiceService,
    public dialog: MatDialog,
    public contentEditing: ContentEditingService,
    public router: Router,
    public postService: PostsService
  ) {
    this.posts$ = this.getPosts();
  }

  public get postForm() {
    return this.contentEditing.createPostForm;
  }

  ngOnInit(): void {
    this.feedOpenerService.changeStateFeedBack.subscribe((state: boolean) => {
      if (state) {
        this.bottomSheetService.open(FeedbackFormComponent);
      }
    });
  }

  public getPosts() {
    return this.homeService.getPosts().pipe(
      map((res) => {
        localStorage.setItem('posts', JSON.stringify(res));
        return res;
      })
    );
  }

  public summary$ = this.homeService.getSummary().pipe(map((res) => res.data));
  public youMightKnow$ = this.homeService
    .youMightKnow()
    .pipe(map((res) => res.data));

  public submitPost() {
    this.router.navigate(['/admin', 'content-editing']);
  }

  public submitPost2() {
    const text = this.postForm.get('text').value;
    if (!text) {
      return;
    }
    const data: any = {
      text,
      images: this.images.value,
      videos: this.videos.value,
      notify: ['PUBLIC'],
      contributors: [],
      postLanguage: 'Turkish',
      postLocation: 'Turkey',
      poll: this.postForm.get('poll').value,
    };
    this.postForm.reset();
    this.homeService.submitPost(this.postForm.value as any).subscribe((res) => {
      this.posts$ = this.getPosts();
    });
  }

  public reportedIds = [];

  public onIntersection(
    {
      target,
      visible,
    }: {
      target: Element;
      visible: boolean;
    },
    post: Post
  ): void {
    if (visible && !this.reportedIds.includes(post?._id)) {
      this.reportedIds.push(post?._id);
      this.postService.reportView(post).subscribe();
    }
  }

  public toggle($event, user) {
    $event.stopPropagation();
    this.followerService.toogleExtended(user).subscribe();
  }

  public get images() {
    return this.postForm.get('images') as FormArray;
  }

  public get videos() {
    return this.postForm.get('videos') as FormArray;
  }

  public pickImage() {
    this.uploadService
      .openUploadDialog$('image', true, {
        allowCrop: false,
      })
      .subscribe((files) => {
        for (let file of files) {
          this.images.push(this.fb.control(file));
        }
      });
  }

  public pickVideo() {
    this.uploadService
      .openUploadDialog$('video', true, {
        allowCrop: false,
      })
      .subscribe((files) => {
        for (let file of files) {
          this.videos.push(this.fb.control(file));
        }
      });
  }

  public testClick() {}

  public isMobile = this.breakPointObserver
    .observe(Breakpoints.Small)
    .pipe(map((result) => result.matches));

  public account1 = {
    image: 'assets/img/acc1.jpeg',
    name: 'Ayşe K.',
    info: 'Teacher',
  };

  public account2 = {
    image: 'assets/img/acc2.jpeg',
    name: 'Roketsan',
    info: 'Şirket • Savunma Sanayi ve Uzay',
  };

  public account3 = {
    image: 'assets/img/acc3.jpeg',
    name: 'Havelsan',
    info: 'Şirket • Savunma ve Bilgi Teknolojileri',
  };

  public account4 = {
    image: 'assets/img/acc4.jpeg',
    name: 'Havelsan',
    info: 'Şirket • Savunma ve Bilgi Teknolojileri',
  };

  public card1 = {
    image: 'assets/img/turkcell.png',
    name: 'Turkcell',
    description: 'Teknik Staj',
    info: 'İstanbul (İş Yerinde) • 2 Ay • Önerilen İlan',
  };
  public card2 = {
    image: 'assets/img/teknokent.png',
    name: 'Antalya Teknokent',
    description: 'Antalya Teknokent Demoday',
    info: 'Antalya • 8 Aralık 2023 • Takip ettiklerinizden yeni etkinlik',
  };
  public card3 = {
    image: 'assets/img/turkcell.png',
    name: 'Turkcell',
    description: 'Teknik Staj',
    info: 'İstanbul (İş Yerinde) • 2 Ay • Önerilen İlan',
  };

  public users = [this.account1, this.account2, this.account3];
  public cards = [this.card1, this.card2, this.card3];

  public post = {
    user: this.account4,
  };

  public posts = [this.post, this.post, this.post];

  public upload(files: FileList) {
    this.uploadService.upload(files[0]);
  }
}
