import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Toast } from '@common/core/toast/toast/toast.service';
import { eventColors } from '@common/core/utils/event-colors';
import { EventsService } from '@common/services/events.service';
import { BehaviorSubject, map, switchMap } from 'rxjs';
import { Event } from 'src/app/models/event';

@Component({
  selector: 'purchase-event-ticket',
  templateUrl: './purchase-event-ticket.component.html',
  styleUrls: ['./purchase-event-ticket.component.scss'],
})
export class PurchaseEventTicketComponent {
  constructor(
    public activatedRoute: ActivatedRoute,
    private events: EventsService,
    private toast: Toast,
    private router: Router
  ) {}

  public eventId = this.activatedRoute.snapshot.params['id'];

  public getColors(event: Event) {
    return eventColors.find((color) => color.value == event.themeColor)?.colors;
  }

  public getAccentColor(event: Event) {
    return eventColors.find((color) => color.value == event.themeColor)
      ?.colors[0];
  }

  public getApplication(event: Event) {
    return event.applications.find(
      (application) =>
        application._id == this.activatedRoute.snapshot.params['applicationId']
    );
  }

  public event$ = this.activatedRoute.params
    .pipe(
      switchMap((params) => {
        return this.events.get(params['id']);
      })
    )
    .pipe(map((response) => response.data));

  public loading$ = new BehaviorSubject<boolean>(false);

  public submit(event: Event) {
    this.loading$.next(true);
    this.events.buyTicket(event._id, this.getApplication(event)._id).subscribe({
      next: (res) => {
        this.loading$.next(false);
        this.toast.open('Bilet satın alındı. İyi eğlenceler!!');
        this.router.navigate(['/event', event._id]);
      },
      error: (err) => {
        this.loading$.next(false);
        this.toast.open(err.error);
        this.router.navigate(['/event', event._id]);
      },
    });
  }
}
