<div class="d-flex posting">
  <img *ngIf="item" [src]="item.creator?.avatar" alt="" class="avatar" />
  <ngx-skeleton-loader
    *ngIf="!item"
    appearance="circle"
    [theme]="{ width: '48px', height: '48px' }"
  >
  </ngx-skeleton-loader>
  <div>
    <ngx-skeleton-loader
      class="name"
      *ngIf="!item"
      [theme]="{
        width: '200px',
        'border-radius': '0',
        height: '15px',
        'margin-bottom': '10px'
      }"
    ></ngx-skeleton-loader>
    <h3 *ngIf="item" class="name" [routerLink]="['/job-posting', item._id]">
      {{ item.creator?.display_name || item.creator?.name }}
    </h3>
    <br *ngIf="!item">
    <ngx-skeleton-loader
      class="short-desc"
      *ngIf="!item"
      [theme]="{ width: '170px', 'border-radius': '0', height: '15px' }"
    ></ngx-skeleton-loader>
    <p *ngIf="item" class="short-desc">{{ item.title }}</p>
    <br *ngIf="!item">
    <ngx-skeleton-loader
      class="details"
      *ngIf="!item"
      [theme]="{ width: '170px', 'border-radius': '0', height: '15px' }"
    ></ngx-skeleton-loader>
    <p *ngIf="item" class="details">{{ item.locations.join(", ") }}</p>
    <button
      *ngIf="item"
      mat-flat-button
      [routerLink]="['/job-posting', item._id]"
      class="button-other"
    >
      Detaylar
    </button>
  </div>
</div>
