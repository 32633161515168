<mat-card class="card">
    <mat-card-title class="title">Topluluk</mat-card-title>
  
    <mat-card-header class="header">
      <div mat-card-avatar class="card-header-image">
        <img [src]="data.avatar" class="card-logo" />
      </div>
  
      <mat-card-title class="header-title" style="cursor: pointer;" [routerLink]="['/community', data._id]">{{ data.name }}</mat-card-title>
  
      <mat-card-subtitle class="header-subtitle">{{
        data.headnote
      }}</mat-card-subtitle>
  
      <mat-card-subtitle class="header-subtitle"
        >Takipçi • {{ data.followerCount }}</mat-card-subtitle
      >
    </mat-card-header>
  
    <mat-card-content class="content">
      <main-text-card></main-text-card>
    </mat-card-content>
    <like-save-card [data]="getMappedData()"></like-save-card>
  </mat-card>
  