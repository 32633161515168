import { AfterViewChecked, AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@common/auth/auth.service';
import { EventsService } from '@common/services/events.service';
import { TextEditorComponent } from '@common/text-editor/text-editor.component';
import { BehaviorSubject } from 'rxjs';
import { EventCalendarNote } from 'src/app/models/relations/events/event-calendar-note';

@Component({
  selector: 'add-calendar-note-dialog',
  templateUrl: './add-calendar-note-dialog.component.html',
  styleUrls: ['./add-calendar-note-dialog.component.scss'],
})
export class AddCalendarNoteDialogComponent implements OnInit, AfterViewInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      day: number;
      month: number;
      year: number;
      oldNote?: EventCalendarNote;
    },
    public fb: FormBuilder,
    public events: EventsService,
    public auth: AuthService,
    public ref: MatDialogRef<AddCalendarNoteDialogComponent>
  ) {}

  @ViewChild('editor') public editor: TextEditorComponent;

  public loading$ = new BehaviorSubject<boolean>(false);

  public formGroup = this.fb.group({
    title: [''],
    description: [''],
  });

  public change() {
    this.formGroup.get('description').setValue(this.editor.getContents());
  }

  public content: string;

  ngOnInit(): void {
    if (this.data?.oldNote) {
      this.formGroup.get('title').setValue(this.data.oldNote.title);
      this.formGroup.get('description').setValue(this.data.oldNote.description);
      this.content = this.data.oldNote.description;
    }
  }

  ngAfterViewInit(): void {
    
  }


  public submit() {
    this.loading$.next(true);
    this.events
      .addCalendarNote(this.auth.selectedMember.profileId, {
        title: this.formGroup.get('title').value,
        description: this.formGroup.get('description').value,
        day: this.data.day,
        month: this.data.month,
        year: this.data.year,
        profileId: this.auth.selectedMember.profileId,
        _id: null,
        profileType: this.auth.selectedMember.profileType,
      })
      .subscribe((response) => {
        this.ref.close(response.data);
      });
  }
}
